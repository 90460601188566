<template>
  <div class="d-flex flex-column">
    <v-data-table
      :headers="headers"
      :items="requestProducts"
      disable-sort
      hide-default-footer
    >
      <!-- TODO: update object mapping -->
      <template v-slot:body="{ items }">
        <tr
          v-for="(item, key) in items"
          :key="key"
          class="request-product__table__tr"
          @click="onRequestProductClick(item)"
        >
          <td
            :class="getClassName('request-product__table__td')"
          >
            <div
              :class="
                [
                  'd-flex',
                  'full-width',
                  'justify-space-between',
                  'justify-md-start'
                ]
              "
            >
              <span v-if="isMobileScreenSize">สินค้า</span>
              <div
                class="d-flex justify-space-between justify-md-start full-width"
              >
                <img
                  :src="`${ item.productImage && item.productImage.url ? item.productImage.url : '' }`"
                  class="product-request__img align-self-start"
                  width="70"
                >
                <div :class="getClassName('product-request__product__detail__container d-flex flex-column pl-4')">
                  <span>{{ item.productName }}</span>
                  <span>x {{ item.requestQty }}</span>
                </div>
              </div>
            </div>
          </td>
          <td
            :class="getClassName('request-product__table__td')"
          >
            <div
              :class="
                [
                  'd-flex',
                  'full-width',
                  'justify-space-between',
                  'justify-md-center'
                ]
              "
            >
              <span v-if="isMobileScreenSize">วัน/เวลา อัพเดทสถานะ</span>
              <div
                class="text-center"
              >
                {{ getUpdatedTime(item.updatedAt) }}
              </div>
            </div>
          </td>
          <td
            :class="getClassName('request-product__table__td')"
          >
            <div
              :class="
                [
                  'd-flex',
                  'full-width',
                  'justify-space-between',
                  'justify-md-center'
                ]
              "
            >
              <span v-if="isMobileScreenSize">สถานะ</span>
              <div
                class="product-request__product__status d-flex justify-center align-center"
              >
                <v-chip
                  :color="getStatusColor(item.status)"
                >
                  {{ getStatusText(item.status) }}
                </v-chip>
              </div>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div class="mt-10 align-self-end">
      <Pagination
        v-if="requestProducts.length > 0"
        :total-items="total"
        :page="page"
        :items="requestProducts"
        :labels="customLabels"
        :page-size="pageSize"
        :styles="customStyles"
        @changePage="onChangePage"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { GET_REQUEST_PRODUCT } from '@/store/_actionTypes';
import { formatDate } from '@/helpers/date';
import Pagination from '@/components/Pagination.vue';

const customStyles = {
  li: {
    display: 'inline-block',
  },
  a: {
    color: '#242424',
  },
};

const customLabels = {
  first: 'First',
  last: 'Last',
  previous: '<',
  next: '>',
};

export default {
  name: 'RequestProductTable',
  components: {
    Pagination,
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    requestProducts: {
      type: Array,
      default: () => [],
    },
    onRequestProductClick: {
      type: Function,
      default: () => {
      },
    },
    status: {
      type: String,
      default: () => '',
    },
  },
  data: () => ({
    pageSize: 5,
    customStyles,
    customLabels,
  }),
  computed: {
    ...mapState('request-product', [ 'total', 'page' ]),
    ...mapState('app', [ 'isMobileScreenSize' ]),
  },
  methods: {
    ...mapActions('request-product', [ GET_REQUEST_PRODUCT ]),
    getClassName(defaultClassName) {
      return this.isMobileScreenSize
          ? `${ defaultClassName } mobile`
          : defaultClassName;
    },
    onChangePage(page) {
      this[GET_REQUEST_PRODUCT](
        {
          accessToken: this.accessToken,
          status: this.status,
          page
        }
      );
    },
    getHeaderText(header) {
      switch (header) {
        case 'product_name': {
          return 'สินค้า'
        }
        case 'updated_date': {
          return 'วัน/เวลา อัพเดทสถานะ'
        }
        case 'status': {
          return 'สถานะ'
        }
        default: {
          return ''
        }
      }
    },
    getStatusColor(status) {
      return status === 'waiting_response_offer'
      || status === 'waiting_deposit'
      ? 'secondary' : '#f6f6f6';
    },
    getStatusText(status) {
      switch (status) {
        case 'reject_request': {
          return 'ถูกปฏิเสธ';
        }
        case 'reject_offer': {
          return 'ปฏิเสธการเสนอราคา';
        }
        case 'waiting_offer': {
          return 'รอเสนอราคา';
        }
        case 'waiting_response_offer': {
          return 'รอตอบรับการเสนอราคา';
        }
        case 'waiting_deposit': {
          return 'รอโอนมัดจำ';
        }
        case 'waiting_register_product': {
          return 'รอสินค้าเข้าระบบ';
        }
        case 'product_registered': {
          return 'สินค้าเข้าระบบแล้ว';
        }
        default: {
          return 'ไม่ทราบสถานะ';
        }
      }
    },
    getUpdatedTime(date) {
      return formatDate(date)
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/shop/pagination.scss';

table {
  padding: 4px 2px;
}

.request-product__table__tr:hover {
  background: unset !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
}

.request-product__table__td {
  padding: 0 16px;

  &:first-child {
    padding: 16px;
  }

  &:last-child {
    padding: 16px;
  }

  &.mobile {
    display: flex;
  }
}

.product-request__img {
  padding: 10px;
  border-radius: 4px;
  border: solid 1px #eeeeee;
}

.product-request__product__detail__container {
  max-width: 350px;
}

.product-request__product__detail__container.mobile {
  max-width: 250px;
  text-align: right;
}

.product-request__product__status > span {
  font-size: 12px !important;
}

.v-data-table-header-mobile,
td:first-child > div > span {
  display: none;
}
</style>