<template>
  <Dialog
    :is-show-dialog="isShowConfirmPaymentDialog"
    width="560"
    @setIsShowDialog="setIsShowConfirmPaymentDialog"
  >
    <div class="confirm-payment d-flex flex-column pl-8 pr-8">
      <span class="title-text d-flex align-self-center">แจ้งชำระเงิน</span>
      <v-divider class="mt-3" />
      <v-form
        ref="form"
        class="mt-6"
      >
        <div class="d-flex flex-column">
          <!--
          <span class="field-text">ธนาคารที่โอน</span>
          <v-select
            v-model="selectedBankId"
            :items="getBankList"
            :rules="rules.selectBankRule"
            append-icon="mdi-chevron-down"
            class="mt-2 mb-3"
            color="secondary"
            dense
            flat
            hide-details="auto"
            item-text="name"
            item-value="id"
            outlined
            solo
          />
          -->
          <span class="field-text">อัพโหลดหลักฐานการชำระเงิน</span>
          <UploadImageFile
            :error-message="fileErrorMessage"
            :file-url="slipImage"
            @setErrorMessage="setFileErrorMessage"
            @setFileUrl="setSlipUrl"
          />
          <v-btn
            :disabled="isShowUploadProgress"
            class="submit-btn d-flex align-self-end mt-6 mb-6"
            color="secondary"
            large
            @click="validateAndSubmit"
          >
            บันทึก
          </v-btn>
        </div>
      </v-form>
    </div>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialog.vue';
import UploadImageFile from '@/components/UploadFile/UploadImageFile.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { CONFIRM_PAYMENT } from '@/store/_actionTypes';

export default {
  name: 'ConfirmPaymentDialog',
  components: { Dialog, UploadImageFile },
  props: {
    isShowConfirmPaymentDialog: {
      type: Boolean,
      default: () => false,
    },
    orderId: {
      type: String,
      default: () => '',
    },
  },
  data: () => ({
    selectedBankId: 'กสิกรไทย',
    paymentDate: null,
    slipImage: '',
    amountPayment: null,
    fileErrorMessage: '',
    timePickerProps: {
      textFieldProps: {
        prependInnerIcon: 'mdi-calendar',
        outlined: true,
        color: 'secondary',
        dense: true,
        hideDetails: 'auto',
        solo: true,
        flat: true,
        rules: [],
      },
      dateProps: {
        headerColor: 'secondary',
        color: 'secondary',
      },
      timePickerProps: {
        headerColor: 'secondary',
        color: 'secondary',
        format: '24hr',
      },
    },
    rules: {
      selectBankRule: [],
      amountOfPaymentRule: [],
    },
  }),
  computed: {
    ...mapState('upload-image', ['isShowUploadProgress']),
    ...mapGetters('payment', ['getBankList']),
    ...mapState('user', ['accessToken']),
  },
  watch: {
    isShowConfirmPaymentDialog() {
      if (!this.isShowConfirmPaymentDialog) {
        this.clearDialog();
      }
    },
    selectedBank() {
      this.rules.selectBankRule = [];
    },
    paymentDate() {
      this.timePickerProps.textFieldProps.rules = [];
    },
    amountPayment() {
      this.rules.amountOfPaymentRule = [];
    },
  },
  methods: {
    ...mapActions('payment', [CONFIRM_PAYMENT]),
    setIsShowConfirmPaymentDialog(value) {
      this.$emit('setIsShowConfirmPaymentDialog', value);
    },
    validateAndSubmit() {
      this.assignRules();
      this.submit();
    },
    async submit() {
      const isSlipImageValid = this.validateSlipImage();
      const isFormValid = this.$refs.form.validate();
      if (isSlipImageValid && isFormValid) {
        const result = await this[CONFIRM_PAYMENT]({
          orderId: this.orderId,
          fileId: this.slipImage,
          paymentBank: 'กสิกรไทย',
          accessToken: this.accessToken,
        });

        if (result) {
          setTimeout(() => {
            this.$router.go();
          }, 2000);
        }
      }
    },
    validateSlipImage() {
      const isSlipImageValid = !!this.slipImage;
      if (!isSlipImageValid) {
        this.setFileErrorMessage('กรุณาอัพโหลดหลักฐานการโอนเงิน');
      }
      return isSlipImageValid;
    },
    assignRules() {
      this.rules.selectBankRule = [(v) => !!v || 'กรุณาเลือกธนาคารที่โอน'];
      this.rules.amountOfPaymentRule = [
        (v) => !!v || 'กรุณาระบุจำนวนเงินที่ชำระ',
        (v) => v > 0 || 'กรุณาระบุจำนวนเงินที่ชำระให้ถูกต้อง',
      ];
      this.timePickerProps.textFieldProps.rules = [
        (v) => !!v || 'กรุณาเลือกวันที่วัน/เวลา/ที่ชำระเงิน',
      ];
    },
    clearDialog() {
      this.selectedBankId = null;
      this.paymentDate = null;
      this.amountPayment = null;
      this.$refs.form.resetValidation();
      this.$refs.timePicker.clearHandler();
      this.setFileErrorMessage('');
      this.setSlipUrl('');
    },
    setSlipUrl(value) {
      this.slipImage = value;
    },
    setFileErrorMessage(value) {
      this.fileErrorMessage = value;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/fonts.scss';

.confirm-payment {
  .title-text {
    @include kanit;
    font-size: 20px;
    line-height: 1.5;
    color: $black-00;
  }

  .field-text {
    @include kanit;
    font-size: 14px;
    line-height: 1.5;
    color: $black-00;
  }

  .upload-btn {
    @include kanit;
    font-size: 14px;
    line-height: 1.5;
  }

  .submit-btn {
    @include kanit;
    font-size: 16px;
    line-height: 1.5;
    width: 20%;
    height: 48px;
  }
}
</style>
