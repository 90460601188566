<template>
  <v-container>
    <div :class="getClassName('d-flex mb-16', 'd-flex flex-column align-center mb-16')">
      <div>
        <UserMenu class="mr-lg-6" />
      </div>
      <div
        class="container pa-0"
      >
        <UserInfo v-if="isUserInfoActive" />
        <OrderHistory v-else-if="isOrderHistoryActive" />
        <RequestProduct v-else-if="isRequestProductActive" />
        <RequestProductOffer v-else-if="isRequestProductOfferActive" />
        <Wishlist v-else-if="isWishlistActive" />
        <OrderHistoryDetails v-else-if="isOrderHistoryDetailsActive" />
        <Referral v-else-if="isReferralActive" />
      </div>
    </div>
  </v-container>
</template>

<script>
import UserMenu from '@/pages/main/views/Profile/components/UserMenu.vue';
import Referral from '@/pages/main/views/Profile/components/Referral.vue';
import OrderHistory from '@/pages/main/views/OrderHistory/OrderHistory.vue';
import Wishlist from '@/pages/main/views/Profile/components/Wishlist.vue';
import RequestProduct from '@/pages/main/views/RequestProduct/RequestProduct.vue';
import RequestProductOffer from '@/pages/main/views/RequestProductOffer/RequestProductOffer.vue';
import OrderHistoryDetails from '@/pages/main/views/OrderHistoryDetails/OrderHistoryDetails.vue';
import UserInfo from '@/pages/main/views/UserInfo/UserInfo.vue';
import { mapState } from 'vuex';

export default {
  name: 'Profile',
  components: {
    Referral,
    UserMenu,
    OrderHistory,
    Wishlist,
    RequestProduct,
    RequestProductOffer,
    OrderHistoryDetails,
    UserInfo,
  },
  computed: {
    ...mapState('app', ['isMobileScreenSize']),
    isUserInfoActive() {
      return this.$route.name === 'UserInfo';
    },
    isOrderHistoryActive() {
      return this.$route.name === 'OrderHistory';
    },
    isOrderHistoryDetailsActive() {
      return this.$route.name === 'OrderHistoryDetails';
    },
    isRequestProductActive() {
      return this.$route.name === 'RequestProduct';
    },
    isRequestProductOfferActive() {
      return this.$route.name === 'RequestProductOffer';
    },
    isWishlistActive() {
      return this.$route.name === 'Wishlist';
    },
    isReferralActive() {
      return this.$route.name === 'Referral';
    },
    getCols() {
      return this.isMobileScreenSize ? 11 : 9;
    },
  },
  methods: {
    getClassName(defaultClassName, mobileClassName) {
      return this.isMobileScreenSize ? mobileClassName : defaultClassName;
    },
  },
};
</script>

<style lang="scss">
.container {
  width: 100%;
}
</style>
