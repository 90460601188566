<template>
  <Dialog
    :is-show-dialog="isShowRequestProductDialogValue"
    @setIsShowDialog="setIsShowRequestProductDialog"
  >
    <div
      v-if="Object.keys(requestProductSelectedData).length !== 0"
      class="d-flex flex-column align-center mt-1"
    >
      <span class="title-text">รายละเอียดการเสนอราคาสินค้า</span>
      <div class="full-width mt-3 mb-6">
        <v-divider />
      </div>
      <img
        width="270"
        :src="`${ requestProductSelectedData.productImage && requestProductSelectedData.productImage.url ? requestProductSelectedData.productImage.url : '' }`"
        class="product-request__img"
      >
      <div class="dialog__product__description d-flex full-width mt-6">
        <div class="dialog__text__header__container pr-6">
          <span class="font-weight-bold">ชื่อสินค้า:</span>
        </div>
        <div>
          <span>{{ requestProductSelectedData.productName }}</span>
        </div>
      </div>
      <div class="dialog__product__description d-flex full-width mt-2">
        <div class="dialog__text__header__container pr-6">
          <span class="font-weight-bold">บริษัทที่จำหน่าย:</span>
        </div>
        <div>
          <span>{{ requestProductSelectedData.productCompany }}</span>
        </div>
      </div>
      <div class="dialog__product__description d-flex full-width mt-2">
        <div class="dialog__text__header__container pr-6">
          <span class="font-weight-bold">รายละเอียดเพิ่มเติม:</span>
        </div>
        <div>
          <span>{{ requestProductSelectedData.description }}</span>
        </div>
      </div>
      <div class="dialog__product__description d-flex full-width mt-2">
        <div class="dialog__text__header__container pr-6">
          <span class="font-weight-bold">จำนวนที่ต้องการซื้อ:</span>
        </div>
        <div>
          <span>{{ requestProductSelectedData.requestQty }} {{ requestProductSelectedData.requestUnit }}</span>
        </div>
      </div>
      <div class="dialog__product__description d-flex full-width mt-2">
        <div class="dialog__text__header__container pr-6">
          <span class="font-weight-bold">สถานะ Request:</span>
        </div>
        <div>
          <span>{{ getStatusText(requestProductSelectedData.status) }}</span>
        </div>
      </div>
      <div
        v-if="requestProductSelectedData.offerPrice"
        class="dialog__product__description d-flex full-width mt-2"
      >
        <div class="dialog__text__header__container pr-6">
          <span class="font-weight-bold">ร้านค้าเสนอราคา:</span>
        </div>
        <div>
          <span>฿ {{ requestProductSelectedData.offerPrice }} / {{ requestProductSelectedData.requestUnit }}</span>
        </div>
      </div>
      <div
        v-if="requestProductSelectedData.offerPrice"
        class="dialog__product__description d-flex full-width mt-2"
      >
        <div class="dialog__text__header__container pr-6">
          <span class="font-weight-bold">จำนวนสินค้าที่หาได้:</span>
        </div>
        <div>
          <span>{{ requestProductSelectedData.offerQty }} {{ requestProductSelectedData.requestUnit }}</span>
        </div>
      </div>
      <div
        v-if="requestProductSelectedData.offerPrice"
        class="dialog__product__description d-flex full-width mt-2"
      >
        <div class="dialog__text__header__container pr-6">
          <span class="font-weight-bold">ราคารวมทั้งสิ้น:</span>
        </div>
        <div>
          <span>฿ {{ requestProductSelectedData.offerTotalAmount }}</span>
        </div>
      </div>
      <div
        v-if="requestProductSelectedData.offerPrice"
        class="dialog__product__description d-flex full-width mt-2">
        <div class="dialog__text__header__container pr-6">
          <span class="font-weight-bold">ราคามัดจำ:</span>
        </div>
        <div>
          <span>฿ {{ requestProductSelectedData.offerDeposit }}</span>
        </div>
      </div>
      <div
        v-if="requestProductSelectedData.status === 'waiting_offer'"
        class="dialog__admin__box py-4 px-6 mt-6"
      >
        <span>กรุณารอเจ้าหน้าที่ติดต่อกลับ</span>
      </div>
      <div
        v-else-if="requestProductSelectedData.status === 'reject_request'"
        class="dialog__admin__box py-4 px-6 mt-6"
      >
        <span>{{ requestProductSelectedData.rejectNote }}</span>
      </div>
      <div
        v-else-if="requestProductSelectedData.status === 'waiting_deposit'"
        class="dialog__admin__box py-4 px-6 mt-6"
      >
        <span>โปรดชำระเงินค่ามัดจำกับทางร้าน</span>
      </div>
      <div
        v-else-if="requestProductSelectedData.status === 'waiting_register_product'"
        class="dialog__admin__box py-4 px-6 mt-6"
      >
        <span>ทางร้านได้ทำการยอมรับสินค้าชิ้นนี้แล้ว กำลังทำการจัดหา</span>
      </div>
      <div
        v-else-if="requestProductSelectedData.status === 'product_registered'"
        class="dialog__admin__box py-4 px-6 mt-6"
      >
        <span>สินค้าอยู่ในระบบแล้ว สามารถสั่งซื้อได้ทันที</span>
      </div>
      <div
        v-if="requestProductSelectedData.status === 'waiting_response_offer'"
        class="mt-6"
      >
        <v-btn
          class="py-6 px-8"
          depressed
          @click="rejectedRequestProduct"
        >
          <span class="dialog__btn">ปฏิเสธ</span>
        </v-btn>
        <v-btn
          class="py-6 px-8 ml-4"
          color="secondary"
          depressed
          @click="acceptedRequestProduct"
        >
          <span class="dialog__btn">ยอมรับ</span>
        </v-btn>
      </div>
    </div>
  </Dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  REJECTED_REQUEST_PRODUCT,
  ACCEPTED_REQUEST_PRODUCT
} from '@/store/_actionTypes';
import Dialog from '@/components/Dialog.vue';

export default {
  name: 'RequestProductDialog',
  components: { Dialog },
  props: {
    isShowRequestProductDialog: {
      type: Boolean,
      default: () => false,
    },
    requestProductSelectedData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState('user', [ 'accessToken' ]),
    isShowRequestProductDialogValue: {
      get() {
        return this.isShowRequestProductDialog;
      },
    },
  },
  methods: {
    ...mapActions('request-product', [ REJECTED_REQUEST_PRODUCT, ACCEPTED_REQUEST_PRODUCT ]),
    setIsShowRequestProductDialog(value) {
      this.$emit('setIsShowRequestProductDialog', value);
    },
    rejectedRequestProduct() {
      const ret = this[REJECTED_REQUEST_PRODUCT]({ accessToken: this.accessToken, payload: { id: this.requestProductSelectedData.id } });
      this.setIsShowRequestProductDialog(false)
      this.$emit('updatingStatus', ret);
    },
    acceptedRequestProduct() {
      const ret = this[ACCEPTED_REQUEST_PRODUCT]({ accessToken: this.accessToken, payload: { id: this.requestProductSelectedData.id } });
      this.setIsShowRequestProductDialog(false)
      this.$emit('updatingStatus', ret);
    },
    getStatusText(status) {
      switch (status) {
        case 'reject_request': {
          return 'ถูกปฏิเสธ';
        }
        case 'reject_offer': {
          return 'ปฏิเสธการเสนอราคา';
        }
        case 'waiting_offer': {
          return 'รอเสนอราคา';
        }
        case 'waiting_response_offer': {
          return 'รอตอบรับการเสนอราคา';
        }
        case 'waiting_deposit': {
          return 'รอโอนมัดจำ';
        }
        case 'waiting_register_product': {
          return 'รอสินค้าเข้าระบบ';
        }
        case 'product_registered': {
          return 'สินค้าเข้าระบบแล้ว';
        }
        default: {
          return 'ไม่ทราบสถานะ';
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/fonts.scss';

.dialog__admin__box > span,
.dialog__product__description > span {
  @include sarabun;
}

.dialog__text__header__container {
  width: 45%;
  text-align: right;
}

.dialog__admin__box {
  font-size: 12px;
  max-width: 450px;
  border-radius: 4px;
  color: #300463;
  background-color: rgba(117, 30, 223, 0.03);
}

.dialog__btn {
  @include kanit;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
}

</style>