<template>
  <div class="d-flex flex-column justify-center my-3">
    <div class="d-flex">
      <div class="profile__img d-flex align-center">
        <img 
          width="100%"
          :src="getUserInfo && getUserInfo.image && getUserInfo.image.url 
            ? getUserInfo.image.url 
            : require('@/assets/images/customer/placeholder-profile.jpeg')
          "
        >
      </div>
      <div class="d-flex align-center ml-4">
        <label
          for="file-upload"
          class="d-flex align-center user-profile-upload"
        >
          <img
            :src="require('@/assets/images/icon/camera-copy.png')"
            class="default__upload__img__icon mr-2"
          >
          <span class="profile__update__text">อัพโหลดรูปภาพ</span>
       </label>
      </div>
      <input
        id="file-upload"
        type="file"
        @change="onFileChange"
      >
    </div>
    <UserInfoDescription 
      title="ชื่อ"
      :description="this.getUserInfo.fullname"
    />
    <UserInfoDescription 
      title="ชื่อสถานประกอบการ"
      :description="this.getUserInfo.storeName"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { uploadImage } from '@/services/upload-image';
import {
  UPDATE_USER_INFORMATION,
  SHOW_SNACKBAR,
  CHANGE_SNACKBAR_MESSAGE,
  CHANGE_SNACKBAR_COLOR,
} from '@/store/_actionTypes';

import UserInfoDescription from './UserInfoDescription.vue';

export default {
  name: 'UserInfoPersonal',
  components: {
    UserInfoDescription,
  },
  computed: {
    ...mapState('user', [ 'accessToken' ]),
    ...mapGetters('user', [ 'getUserInfo' ]),
  },
  methods: {
    ...mapActions('app', [ SHOW_SNACKBAR, CHANGE_SNACKBAR_MESSAGE, CHANGE_SNACKBAR_COLOR ]),
    ...mapActions('user', [ UPDATE_USER_INFORMATION ]),
    async onFileChange(e) {
      const file = e.target.files[0];
      const form = new FormData();
      form.append('file', file);

      try {
        const result = await uploadImage(form, this.accessToken);
        await this[UPDATE_USER_INFORMATION]({ imageFileId: result.id });
        this[SHOW_SNACKBAR](true);
        this[CHANGE_SNACKBAR_COLOR]('green');
        this[CHANGE_SNACKBAR_MESSAGE]('อัพโหลดสำเร็จ');
      } catch (error) {
        this[SHOW_SNACKBAR](true);
        this[CHANGE_SNACKBAR_COLOR]('red');
        this[CHANGE_SNACKBAR_MESSAGE](error.message);
      }
    }
  },
};
</script>

<style lang="scss">

.user-profile-upload {
  cursor: pointer;
}

.profile__update__text {
    font-family: Kanit;
    font-size: 12px;
    color: #751edf;
}

.profile__img {
    width: 100px;
    height: 100px;
}

</style>