<template>
  <div :class="getClassName('d-flex flex-column','d-flex flex-column align-center')">
    <span class="title-text text-start">{{ title }}</span>
    <v-img
      :src="bannerImage"
      :srcset="bannerImageSet"
      class="mt-3"
      min-width="350"
    />
    <span class="sub-title mt-6">{{ ruleTitle }}</span>
    <div
      v-for="(ruleText, index) in rules"
      :key="index"
      class="align-self-start mt-2"
    >
      <span :class="getClassName('rule-text', 'rule-text mobile')">{{ ruleText }}</span>
    </div>

    <span class="sub-title mt-6">{{ referralCodeTitle }}</span>
    <div class="mt-2 pb-16 d-flex">
      <span class="ref-text">{{ getRefCode }}</span>
      <copy-to-clipboard
        :text="getRefCode"
        @copy="handleCopy"
      >
        <div class="copy-img">
          <v-img :src="copyIcon" />
        </div>
      </copy-to-clipboard>
    </div>
  </div>
</template>

<script>
import Banner from '@/assets/images/banner/referral-banner.png';
import Banner2x from '@/assets/images/banner/referral-banner@2x.png';
import Banner3x from '@/assets/images/banner/referral-banner@3x.png';
import CopyIcon from '@/assets/images/logo/ic-copy.svg';
import { mapActions, mapGetters, mapState } from 'vuex';
import CopyToClipboard from 'vue-copy-to-clipboard';
import { CHANGE_SNACKBAR_MESSAGE, SHOW_SNACKBAR } from '@/store/_actionTypes';

export default {
  name: 'Referral',
  components: {
    CopyToClipboard,
  },
  props: {
    className: {
      type: String,
      default: () => '',
    },
  },
  data: () => ({
    title: 'แนะนำเพื่อน',
    bannerImage: Banner,
    bannerImageSet: `${Banner2x} 2x, ${Banner3x} 3x`,

    ruleTitle: 'เงื่อนไขการใช้ Referral code',
    rules: ['1. Copy ‘Referal code’ แล้วส่งให้เพื่อนของคุณนำไปกรอกในหน้า ‘สมัครสมาชิก’',
      '2. เพื่อนของคุณต้องมีการเปิดบิลสินค้ากับนรินทร์เภสัชครั้งแรก',
      '3. คุณจะได้รับ 299 Capsule เข้าระบบไปเลยฟรีๆ ซึ่งแต้ม Capsule  สามารถนำไปแลกเป็นของสมนาคุณในระบบของเราได้ตามต้องการ',
      '4. สามารถนำ ‘Referral code’ ส่งให้เพื่อนคนอื่นๆ อีกได้ไม่จำกัด'],
    referralCodeTitle: 'Referral code:',
    copyIcon: CopyIcon,
    timeout: 2000,
  }),
  computed: {
    ...mapState('app', ['isMobileScreenSize']),
    ...mapGetters('user', ['getRefCode']),
  },
  methods: {
    ...mapActions('app', [SHOW_SNACKBAR, CHANGE_SNACKBAR_MESSAGE]),
    getClassName(defaultClassName, mobileClassName) {
      return this.isMobileScreenSize ? mobileClassName : defaultClassName;
    },
    handleCopy() {
      this[SHOW_SNACKBAR](true);
      this[CHANGE_SNACKBAR_MESSAGE]('คัดลอกสำเร็จ');
    },
  },
};

</script>

<style lang="scss">
@import '@/styles/variables.scss';
@import '@/styles/fonts.scss';

.title-text {
  @include kanit;
  font-size: 20px;
  color: $black-00;
}

.sub-title {
  @include sarabun;
  font-size: 16px;
  font-weight: 600;
  color: $black-00;
}

.rule-text {
  @include sarabun;
  font-size: 16px;
  color: $black-00;

  &.mobile {
    font-size: 14px;
  }
}

.ref-text {
  @include kanit;
  font-size: 14px;
  color: $primary-color-02;
  background-color: rgba(149, 61, 255, 0.05);
  padding: 8px 40px;
  border-radius: 4px 0 0 4px;
}

.copy-img {
  background-color: $primary-color-02;
  padding: 12px;
  border-radius: 0 4px 4px 0;

  &:hover {
    cursor: pointer;
  }
}

</style>
