<template>
  <div class="d-flex flex-column justify-center mb-3">
    <UserInfoShippingAddressDescription 
      v-for="(shippingAddress, n) in this.shippings"
      :key="`shipping-address-${n}`"
      :shipping="shippingAddress"
    />
    <div
      class="profile__file__upload__edit__text-container d-flex align-center"
      @click="addShippingAddress"
    >
      <img
        class="mr-1"
        :src="EditIcon"
      >
      <span class="profile__file__upload__edit__text">
        เพิ่มที่อยู่จัดส่ง
      </span>
    </div>
    <UserInfoAddShippingAddressDialog
      :is-show-add-shipping-address-dialog="isShowAddShippingAddressDialog"
      @setIsShowAddShippingAddressDialog="setIsShowAddShippingAddressDialog"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import EditIcon from '@/assets/images/icon/group-9.png';

import UserInfoAddShippingAddressDialog from './UserInfoAddShippingAddressDialog.vue';
import UserInfoShippingAddressDescription from './UserInfoShippingAddressDescription.vue';

export default {
  name: 'UserInfoShippingAddress',
  components: {
    UserInfoAddShippingAddressDialog,
    UserInfoShippingAddressDescription,
  },
  data: () => ({
    EditIcon,
    isShowAddShippingAddressDialog: false,
  }),
  computed: {
    ...mapState('user', [ 'shippings' ]),
  },
  methods: {
    addShippingAddress() {
      this.isShowAddShippingAddressDialog = true;
    },
    setIsShowAddShippingAddressDialog(value) {
      this.isShowAddShippingAddressDialog = value;
    },
  },
};
</script>
