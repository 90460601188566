<template>
  <div :class="getClassName">
    <div class="d-flex mt-2 justify-center mb-4">
      <v-col
        v-for="({}, index) in 5"
        :key="index"
        class="d-flex flex-column align-center"
        @click="onItemClick(index)"
      >
        <img
          class="icon-img"
          :src="getIconTab(index)"
        >
      </v-col>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UserMenuMobile',
  props: {
    className: {
      type: String,
      default: () => '',
    },
    getIconTab: {
      type: Function,
      default: () => '',
    },
    onItemClick: {
      type: Function,
      default: () => {
      },
    },
  },
  computed: {
    getClassName() {
      return this.className === '' ? 'd-flex flex-row' : this.className;
    },
  },
};

</script>

<style lang="scss">

.icon-img {
  width: 24px;
  height: 24px;
}
</style>
