<template>
  <div class="order-history-details mb-3">
    <div class="title-text text-left mb-3">
      ประวัติการสั่งซื้อ
    </div>
    <div class="mb-5 d-flex justify-space-between">
      <div>
        <span class="order-id mr-3">หมายเลขคำสั่งซื้อ {{ getOrderNumber }}</span>
        <span class="order-date">(วันที่สั่งซื้อ: {{ getOrderCreated }})</span>
      </div>
      <div
        v-if="isShowTrackingNumber"
        class="d-flex align-center"
      >
        <span class="text mr-3">หมายเลขติดตามพัสดุ: {{ deliveryTrackingNumber }}</span>
        <a
          :href="deliveryProviderSrc"
          target="_blank">
          <v-img
            :src="deliveryProviderImage"
            :srcset="deliveryProviderImageSrcSet"
            height="30"
            width="80"
          />
        </a>
      </div>
    </div>

    <Details
      :order-details="getOrderDetails"
      :order-status="order.status"
    />

    <div :class="getClassName(isMobileScreenSize, 'additional d-flex mt-3', 'mobile')">
      <div :class="getClassName(isMobileScreenSize, 'additional-left', 'mobile')">
        <span class="label-text">ที่อยู่จัดส่ง:</span>
        <Location
          class="mt-3 mb-4"
          :name="getDeliveryLocationName"
          :address="getDeliveryLocationAddress"
          :phone-number="getDeliveryLocationPhoneNumber"
        />
        <v-divider />
        <div class="d-flex flex-column mt-4 mb-4">
          <span class="label-text mb-2">สถานที่นัดรับ/จัดส่งสินค้า:</span>
          <span class="delivery-details-text">{{ pickupLocation }}</span>
        </div>
        <v-divider />
        <PaymentOption />
      </div>
      <div
        :class="getClassName(isMobileScreenSize, 'additional-right', 'mobile')"
      >
        <Summary />
        <div
          v-if="isShowInteractionBtns"
          class="btn-group d-flex justify-space-between mt-6"
        >
          <!-- <v-btn
            class="btn border-grey"
            outlined
            @click="setShowCancelOrderDialog(true)"
          >
            ยกเลิกคำสั่งซื้อ
          </v-btn> -->
          <v-btn
            class="btn"
            color="secondary"
            @click="setShowConfirmPaymentDialog(true)"
          >
            แจ้งชำระเงิน
          </v-btn>
        </div>
      </div>
    </div>
    <ConfirmPaymentDialog
      :order-id="getCurrentOrderId"
      :is-show-confirm-payment-dialog="isShowConfirmPaymentDialog"
      @setIsShowConfirmPaymentDialog="setShowConfirmPaymentDialog"
    />
    <CancelOrderDialog
      :order-id="getCurrentOrderId"
      :is-show-cancel-order-dialog="isShowCancelOrderDialog"
      @setIsShowCancelOrderDialog="setShowCancelOrderDialog"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

import Location from '@/components/Location.vue';
import { getClassName } from '@/helpers/className';
import { formatDate } from "@/helpers/date";

import { SHOW_CONFIRM_PAYMENT_DIALOG, SHOW_CANCEL_ORDER_DIALOG, GET_ORDER_BY_ID_ACTION } from '@/store/_actionTypes';
import Details from './components/Details.vue';
import PaymentOption from './components/PaymentOption.vue';
import Summary from './components/Summary.vue';
import ConfirmPaymentDialog from './components/ConfirmPaymentDialog.vue';
import CancelOrderDialog from './components/CancelOrderDialog.vue';

export default {
  name: 'OrderHistoryDetails',
  components: {
    CancelOrderDialog,
    ConfirmPaymentDialog,
    Details,
    Location,
    PaymentOption,
    Summary,
  },
  computed: {
    ...mapState('app', ['isMobileScreenSize']),
    ...mapState('payment', ['isShowConfirmPaymentDialog']),
    ...mapState('order', ['isShowCancelOrderDialog', 'order']),
    ...mapState('user', [ 'accessToken' ]),
    ...mapGetters('order', ['getOrderDetails', 'pickupLocation', 'isShowInteractionBtns', 'deliveryProvider', 'deliveryTrackingNumber', 'isShowTrackingNumber']),
    getCurrentOrderId() {
      return this.$route.params.orderId;
    },
    getOrderNumber() {
      return this.order?.orderNumber;
    },
    getOrderCreated() {
      return formatDate(this.order?.orderDate);
    },
    getDeliveryLocationName() {
      return this.order?.shipping?.name;
    },
    getDeliveryLocationAddress() {
      return this.order?.shipping?.address;
    },
    getDeliveryLocationPhoneNumber() {
      return this.order?.shipping?.phoneNumber;
    },
    deliveryProviderImage() {
      return this.deliveryProvider?.iconImage;
    },
    deliveryProviderImageSrcSet() {
      return this.deliveryProvider?.iconImageSrcSet;
    },
    deliveryProviderSrc() {
      return this.deliveryProvider?.src;
    },
  },
  created() {
    this[GET_ORDER_BY_ID_ACTION]({ accessToken: this.accessToken, orderId: this.$route.params?.orderId })
  },
  methods: {
    ...mapActions('payment', [SHOW_CONFIRM_PAYMENT_DIALOG]),
    ...mapActions('order', [SHOW_CANCEL_ORDER_DIALOG, GET_ORDER_BY_ID_ACTION]),
    getClassName,
    setShowConfirmPaymentDialog(value) {
      this[SHOW_CONFIRM_PAYMENT_DIALOG](value);
    },
    setShowCancelOrderDialog(value) {
      this[SHOW_CANCEL_ORDER_DIALOG](value);
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/fonts.scss';

.order-history-details {
  .label-text {
    @include kanit;
    font-size: 20px;
    line-height: 1.5;
    color: $black-00;
  }

  .order-id {
    @include sarabun;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.57;
    color: $black-00;
  }

  .order-date {
    @include sarabun;
    font-size: 12px;
    line-height: 1.5;
    color: $grey-02;
  }

  .additional {
    width: 100%;

    &-left {
      width: 50%;
      padding-right: 24px;

      &.mobile {
        width: 100%;
        padding-right: 0;
      }
    }

    &-right {
      width: 50%;
      padding-left: 24px;

      &.mobile {
        width: 100%;
        padding-left: 0;
      }
    }

    &.mobile {
      flex-direction: column
    }
  }

  .text {
    @include sarabun;
    line-height: 1.57;
    color: $black-00;

    &-title {
      @include sarabun;
      font-weight: 600;
      font-size: 14px;
      color: $black-00;
    }
  }

  .btn-group {
    width: 100%;

    .btn {
      @include kanit;

      // width: 48%;
      width: 100%;
      height: 48px;

      font-size: 16px;
      line-height: 1.5;

      &.border-grey {
        border-color: $white-01 !important;
      }
    }
  }
}

.v-application--is-ltr .v-stepper__step__step {
  margin-right: 0;
}

</style>
