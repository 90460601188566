<template>
  <div :class="getClassName">
    <v-list
      min-width="200"
      min-height="38"
      rounded
    >
      <v-list-item-group
        class="mt-2"
        :value="currentTab"
        mandatory
        color="#953dff"
      >
        <v-list-item
          v-for="({text}, index) in tabs"
          :key="index"
          @click="onItemClick(index)"
        >
          <img
            class="mr-4"
            :src="getIconTab(index)"
          >
          <span class="tab-text mr-4">{{ text }}</span>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
export default {
  name: 'UserMenuDesktopMode',
  props: {
    className: {
      type: String,
      default: () => '',
    },
    getIconTab: {
      type: Function,
      default: () => '',
    },
    onItemClick: {
      type: Function,
      default: () => {
      },
    },
    currentTab: {
      type: Number,
      default: () => 0,
    },
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    getClassName() {
      return this.className === '' ? 'd-flex flex-row' : this.className;
    },
  },
};
</script>

<style lang="scss">
.tab-text {
  font-family: Kanit;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
}
</style>
