<template>
  <div>
    <Dialog
      :is-show-dialog="isShowUpdateContactDialogValue"
      title="แก้ไขข้อมูลติดต่อ"
      @setIsShowDialog="setIsShowDialog"
    >
      <v-form
        ref="form"
        v-model="isFormValid"
        class="add-delivery-location"
        lazy-validation
      >
        <TextField
          :value="address"
          :rules="addressRules"
          title="บ้านเลขที่ / ถนน"
          required
          @setValue="setAddress"
        />

        <TextField
          :value="customerPhone"
          title="เบอร์โทร"
          @setValue="setCustomerPhone"
        />

         <TextField
          :value="customerEmail"
          :rules="customerEmailRules"
          title="อีเมล"
          required
          @setValue="setCustomerEmail"
        />

        <TextField
          :value="customerFacebook"
          title="Facebook"
          @setValue="setCustomerFacebook"
        />

        <TextField
          :value="customerLineId"
          title="LINE ID"
          @setValue="setCustomerLineId"
        />

        <div class="d-flex justify-end">
          <v-btn
            class="btn back"
            color="white"
            width="120"
            height="48"
            @click="back()"
          >
            กลับ
          </v-btn>
          <v-btn
            class="btn save"
            color="secondary"
            width="120"
            height="48"
            @click="save()"
          >
            บันทึก
          </v-btn>
        </div>
      </v-form>
    </Dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import Dialog from '@/components/Dialog.vue';
import TextField from '@/components/TextField.vue';
import Select from '@/components/Select.vue';

import {
  UPDATE_USER_INFORMATION,
  SHOW_SNACKBAR,
  CHANGE_SNACKBAR_MESSAGE,
  CHANGE_SNACKBAR_COLOR,
} from '@/store/_actionTypes';

export default {
  name: 'UserInfoUpdateContactDialog',
  components: {
    Dialog,
    TextField,
    Select,
  },
  props: {
    isShowUpdateContactDialog: {
      type: Boolean,
      default: () => false,
    },
    userInfo: {
      type: Object,
      default: () => {},
    }
  },
  data: () => ({
    isFormValid: false,
    address: '',
    addressRules: [
      (v) => !!v || 'กรุณากรอกบ้านเลขที่ / ถนน',
    ],
    customerPhone: '',
    customerEmail: '',
    customerEmailRules: [
      (v) => !!v || 'กรุณากรอกอีเมล',
      (v) => /.+@.+\..+/.test(v) || 'อีเมลไม่ถูกต้อง',
    ],
    customerFacebook: '',
    customerLineId: '',
  }),
  computed: {
    isShowUpdateContactDialogValue: {
      get() {
        return this.isShowUpdateContactDialog;
      },
    },
  },
  mounted() {
    this.address = this.userInfo.storeAddress;
    this.customerPhone = this.userInfo.phoneNumber;
    this.customerEmail = this.userInfo.email;
    this.customerFacebook = this.userInfo.facebook;
    this.customerLineId = this.userInfo.line;
  },
  methods: {
    ...mapActions('app', [ SHOW_SNACKBAR, CHANGE_SNACKBAR_MESSAGE, CHANGE_SNACKBAR_COLOR ]),
    ...mapActions('user', [ UPDATE_USER_INFORMATION ]),
    setIsShowDialog(value) {
      this.$emit('setIsShowUpdateContactDialog', value);
    },
    setAddress(value) {
      this.address = value
    },
    setCustomerPhone(value) {
      this.customerPhone = value
    },
    setCustomerEmail(value) {
      this.customerEmail = value
    },
    setCustomerFacebook(value) {
      this.customerFacebook = value
    },
    setCustomerLineId(value) {
      this.customerLineId = value
    },
    async save() {
      if (!this.$refs.form.validate()) return;
      const userInfo = {
        storeAddress: this.address,
        phoneNumber: this.customerPhone,
        email: this.customerEmail,
        facebook: this.customerFacebook,
        line: this.customerLineId,
      };

      try {
        await this[UPDATE_USER_INFORMATION](userInfo);

        this[CHANGE_SNACKBAR_MESSAGE]('แก้ไขที่อยู่สำเร็จ');
        this[CHANGE_SNACKBAR_COLOR]('green');
        this[SHOW_SNACKBAR](true);

        this.back();
      } catch (error) {
        this[CHANGE_SNACKBAR_MESSAGE]('ล้มเหลว กรุณาลองใหม่อีกครั้ง');
        this[CHANGE_SNACKBAR_COLOR]('red');
        this[SHOW_SNACKBAR](true);
      }
    },
    back() {
      this.setIsShowDialog(false);
    },
  },
};
</script>

<style lang="scss">
  .add-delivery-location {
    .v-input--selection-controls {
      margin: 0 !important;
    }

    .btn {
      font-family: Kanit;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;

      &.back {
        border: none;
        box-shadow: none;
        color: $black-00;
      }

      &.save {
        box-shadow: none;
      }
    }

    .checkbox {
      label {
        font-family: Kanit;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        color: $black-00;
      }
    }
  }
</style>
