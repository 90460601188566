<template>
  <div class="order-history">
    <v-data-table
      :headers="headers"
      :items="getTableOrders"
      class="table"
      disable-sort
      hide-default-footer
    >
      <template v-slot:top>
        <div :class="getClassName('table-header d-flex justify-space-between mb-3')">
          <div class="title-text">
            ประวัติการสั่งซื้อ
          </div>
          <div :class="getClassName('table-sub-header d-flex align-center')">
            <span class="mr-3">สถานะใบสั่งซื้อ</span>
            <v-menu
              key="Large"
              rounded="lg"
              offset-y
            >
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  class="order-status__menu__button d-flex justify-center align-center"
                  v-bind="attrs"
                  small
                  depressed
                  v-on="on"
                >
                  {{ orderStatus }}
                  <v-icon
                    dark
                    right
                    medium
                  >
                    mdi-chevron-down
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, n) in orderStatusItems"
                  :key="n"
                  link
                  @click="setOrderStatus(item)"
                >
                  <v-list-item-title
                    class="order-status__menu__text"
                    v-text="item"
                  />
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </template>

      <template v-slot:body="{ items }">
        <tr
          v-for="(item, key) in items"
          :key="key"
          :class="getClassName('table-row')"
          @click="clickRow(item)"
        >
          <template
            v-for="(subItem, subItemKey) in item"
          >
            <td
              v-if="subItemKey != 'id'"
              :key="subItemKey"
              class="table-field"
            >
              <div :class="getClassName('table-field--value')">
                <span v-if="isMobileScreenSize">{{ getHeaderText(subItemKey) }}</span>

                <v-btn
                  v-if="subItemKey === 'paymentStatus'"
                  :color="getPaymentStatusBtnColor(subItem)"
                  class="table-payment-status-btn"
                  rounded
                >
                  {{ getPaymentStatus(subItem) }}
                </v-btn>
                <div
                  v-else-if="subItemKey === 'productImages'"
                  class="d-flex"
                >
                  <img
                    v-for="(image, imageKey) in filterProductImages(subItem)"
                    :key="imageKey"
                    :src="image || NotFoundProductImage"
                    class="table-product-image"
                  >
                  <div
                    v-if="getRemainingProductImages(subItem) !== 0"
                    class="d-flex align-center justify-center table-remaining-product-image"
                  >
                    +{{ getRemainingProductImages(subItem) }}
                  </div>
                </div>
                <div v-else-if="subItemKey === 'latestUpdated'">
                  {{ getLastUpdateTime(subItem) }}
                </div>
                <div v-else>
                  {{ subItem }}
                </div>
              </div>
            </td>
          </template>
        </tr>
      </template>
    </v-data-table>
     <div class="d-flex mt-10 justify-end">
      <Pagination
        v-if="getTableOrders.length > 0"
        :total-items="total"
        :page="page"
        :items="getTableOrders"
        :labels="customLabels"
        :page-size="pageSize"
        :styles="customStyles"
        @changePage="onChangePage"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { formatDate } from '@/helpers/date';
import { GET_ORDERS_ACTION } from '@/store/_actionTypes';
import Select from '@/components/Select.vue';
import Pagination from '@/components/Pagination.vue';
import NotFoundProductImage from '@/assets/images/medicine/not-found-product.png';

const customStyles = {
  li: {
    display: 'inline-block',
  },
  a: {
    color: '#242424',
  },
};

const customLabels = {
  first: 'First',
  last: 'Last',
  previous: '<',
  next: '>',
};

export default {
  name: 'OrderHistory',
  components: {
    Select,
    Pagination,
  },
  data: () => ({
    NotFoundProductImage: NotFoundProductImage,
    orderStatus: 'ทั้งหมด',
    orderStatusItems: [
      'ทั้งหมด',
      'รอการชำระเงิน',
      'ตรวจสอบการชำระเงิน',
      'เตรียมสินค้า',
      'รอส่งสินค้า',
      'รอรับสินค้า',
      'จัดส่งสินค้าแล้ว',
      'รับสินค้าแล้ว',
      'เครดิต',
      'เกินกำหนดชำระ',
      'ชำระเงินแล้ว',
      'ยกเลิกคำสั่งซื้อ',
    ],
    headers: [
      { text: 'หมายเลขคำสั่งซื้อ', value: 'orderNumber' },
      { text: 'รายการสินค้า', value: 'productImages', sortable: false },
      { text: 'ราคารวม', value: 'totalPrice', sortable: false },
      { text: 'วัน/เวลา อัพเดทสถานะ', value: 'latestUpdated', sortable: false },
      { text: 'สถานะการจ่ายเงิน', value: 'paymentStatus', sortable: false },
    ],
    customStyles,
    customLabels,
    pageSize: 5,
  }),
  computed: {
    ...mapState('order', [ 'orders', 'total', 'page' ]),
    ...mapState('app', [ 'isMobileScreenSize' ]),
    ...mapState('user', [ 'accessToken', 'userInfo' ]),
    ...mapGetters('order', [ 'getTableOrders' ])
  },
  created() {
    if (!this.$route.query.filter) {
      this.orderStatus = 'ทั้งหมด';
    } else {
      this.orderStatus = this.getPaymentStatus(this.$route.query.filter);
    }

    this[GET_ORDERS_ACTION]({ accessToken: this.accessToken, status: this.getPaymentStatusValue(this.orderStatus) });
  },
  methods: {
    ...mapActions('order', [ GET_ORDERS_ACTION ]),
    setOrderStatus(value) {
      this.orderStatus = value;
      this[GET_ORDERS_ACTION]({ accessToken: this.accessToken, status: this.getPaymentStatusValue(value) });
    },
    getClassName(defaultClassName) {
      return this.isMobileScreenSize ? `${ defaultClassName } mobile` : defaultClassName;
    },
    getHeaderText(headerValue) {
      const header = this.headers.find(({ value }) => value === headerValue);
      if (!header) return '';
      return `${ header.text }:`;
    },
    getPaymentStatusBtnColor(status) {
      return status === 'pending_payment' ? 'primary' : '';
    },
    getPaymentStatusValue(status) {
      switch (status) {
        case 'รอการชำระเงิน':
          return 'pending_payment';
        case 'ตรวจสอบการชำระเงิน':
          return 'validate_payment';
        case 'เตรียมสินค้า':
          return 'prepare_order';
        case 'รอส่งสินค้า':
          return 'pending_delivery';
        case 'รอรับสินค้า':
          return 'pending_recieve';
        case 'จัดส่งสินค้าแล้ว':
          return 'delivered';
        case 'รับสินค้าแล้ว':
          return 'recieved';
        case 'เครดิต':
          return 'credit';
        case 'เกินกำหนดชำระ':
          return 'credit_overdue';
        case 'ชำระเงินแล้ว':
          return 'paid';
        case 'ยกเลิกคำสั่งซื้อ':
          return 'cancel';
        default:
          return '';
      }
    },
    getPaymentStatus(status) {
      switch (status) {
        case 'pending_payment':
          return 'รอการชำระเงิน';
        case 'validate_payment':
          return 'ตรวจสอบการชำระเงิน';
        case 'prepare_order':
          return 'เตรียมสินค้า';
        case 'pending_delivery':
          return 'รอส่งสินค้า';
        case 'pending_recieve':
          return 'รอรับสินค้า';
        case 'delivered':
          return 'จัดส่งสินค้าแล้ว';
        case 'recieved':
          return 'รับสินค้าแล้ว';
        case 'credit':
          return 'เครดิต';
        case 'credit_overdue':
          return 'เกินกำหนดชำระ';
        case 'paid':
          return 'ชำระเงินแล้ว';
        case 'cancel':
          return 'ยกเลิกคำสั่งซื้อ';
        default:
          return 'ทั้งหมด';
      }
    },
    filterProductImages(productImages) {
      if (productImages.length > 4) {
        return productImages.slice(0, 4);
      }
      return productImages;
    },
    getRemainingProductImages(productImages) {
      if (productImages.length < 4) {
        return 0;
      }
      return productImages.length - 4;
    },
    clickRow(item) {
      if (!item) return;
      this.$router.push(`/order-history/${item.id}`);
    },
    getLastUpdateTime(lastUpdateTime) {
      return formatDate(lastUpdateTime);
    },
    onChangePage(page) {
      this[GET_ORDERS_ACTION]({ accessToken: this.accessToken, status: this.getPaymentStatusValue(this.orderStatus), page: page });
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/fonts.scss';
@import '@/styles/shop/pagination.scss';

.order-history {
  .text-title {
    @include kanit;
    font-size: 20px;
    line-height: 1.5;
    color: $black-00;
  }

  .table {
    th {
      @include sarabun;
      font-size: 14px;
      line-height: 1.57;
      color: $black-00 !important;
    }

    &-header {
      &.mobile {
        flex-direction: column;
      }
    }

    &-sub-header {
      &.mobile {
        justify-content: space-between;
        padding: 0 10px;
      }
    }

    &-row {
      height: 54px;

      &:hover {
        cursor: pointer;
        padding: 12px 30px 12px 12px;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
      }

      &.mobile {
        display: flex;
        flex-direction: column;
        height: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.16);
        padding: 1%;
      }
    }

    &-field {
      padding: 1%;

      &--value {
        @include sarabun;
        font-size: 14px;
        line-height: 1.57;
        color: $black-00;

        &.mobile {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }

    .v-data-table-header-mobile {
      display: none;
    }

    &-payment-status-btn {
      height: 28px !important;
      border-radius: 14px;
      box-shadow: none;

      span {
        @include sarabun;
        font-size: 12px;
        line-height: 1.5;
      }
    }

    &-product-image {
      width: 30px;
      height: 30px;
      margin-right: 4px;
      padding: 2px 1px 1px 2px;
      border: solid 1px $white-01;
      background-color: $white-00;
    }

    &-remaining-product-image {
      @include sarabun;
      font-size: 12px;
      font-weight: 600;
      line-height: 1.5;
      color: $black-00;

      width: 30px;
      height: 30px;
      opacity: 0.5;
      background-color: $black-01;
      color: $white-00;
    }
  }
}

.order-status__menu__button {
    font-family: Sarabun;
    font-size: 14px !important;
    line-height: 1.57;
    letter-spacing: unset !important;
    color: #242424 !important;
    background-color: rgba(117, 30, 223, 0.05) !important;
}

.order-status__menu__text {
    font-family: Sarabun;
    font-size: 14px !important;
    line-height: 1.57 !important;
    color: #242424;
}
</style>
