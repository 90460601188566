<template>
  <div :class="getClassName">
    <v-card
      class="mx-auto d-flex flex-column align-center"
      flat
      tile
    >
      <img
        class="user-img"
        :src="getUserInfo && getUserInfo.image && getUserInfo.image.url 
          ? getUserInfo.image.url 
          : userImage
        "
      >
      <span class="username-text mt-2">{{ getUserInfo.fullname }}</span>
      <span class="store-text mt-1">{{ getUserInfo.storeName }}</span>

      <UserMenuMobileMode
        v-if="isMobileScreenSize"
        :get-icon-tab="getTabIcon"
        :on-item-click="navigateProfileTab"
      />

      <UserMenuDesktopMode
        v-else
        :get-icon-tab="getTabIcon"
        :on-item-click="navigateProfileTab"
        :current-tab="getCurrentTab"
        :tabs="tabs"
      />
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { CURRENT_SELECTED_TAB, NAVIGATE_USER_PROFILE } from '@/store/_actionTypes';

import UserMenuMobileMode from '@/pages/main/views/Profile/components/UserMenuMobileMode.vue';
import UserMenuDesktopMode from '@/pages/main/views/Profile/components/UserMenuDesktopMode.vue';

import UserIcon from '@/assets/images/logo/ic-profile-user.svg';
import OrderHistoryIcon from '@/assets/images/logo/ic-order-history.svg';
import RequestProductIcon from '@/assets/images/logo/ic-request-product.svg';
import WishlistIcon from '@/assets/images/logo/ic-wishlist.svg';
import ReferralIcon from '@/assets/images/logo/ic-referral.svg';
import OrderHistoryIconActive from '@/assets/images/logo/ic-order-history-active.svg';
import RequestProductIconActive from '@/assets/images/logo/ic-request-product-active.svg';
import WishlistIconActive from '@/assets/images/logo/ic-wishlist-active.svg';
import ReferralIconActive from '@/assets/images/logo/ic-referral-active.svg';
import UserIconActive from '@/assets/images/logo/ic-profile-user-active.svg';
import UserLogo from '@/assets/images/customer/placeholder-profile.jpeg';

export default {
  name: 'UserMenuDesktop',
  components: {
    UserMenuDesktopMode,
    UserMenuMobileMode,
  },
  props: {
    className: {
      type: String,
      default: () => '',
    },
  },
  data: () => ({
    userImage: UserLogo,
    tabs: [
      {
        text: 'ข้อมูลส่วนตัว',
      },
      {
        text: 'ประวัติการสั่งซื้อ',
      },
      {
        text: 'Request สินค้า',
      },
      {
        text: 'รายการ Wishlist',
      },
      {
        text: 'แนะนำเพื่อน',
      },
    ],
  }),
  computed: {
    ...mapState('app', ['isMobileScreenSize']),
    ...mapGetters('profile', ['getCurrentTab']),
    ...mapGetters('user', ['getUserInfo']),
    getClassName() {
      return this.className === '' ? 'd-flex flex-row' : this.className;
    },
  },
  watch: {
    $route(to) {
      this[CURRENT_SELECTED_TAB](to.name);
    },
  },
  created() {
    this[CURRENT_SELECTED_TAB](this.$route.name);
    this.userImage = this.getUserInfo?.image?.url ? this.getUserInfo.image.url : UserLogo;
  },
  methods: {
    ...mapActions('profile', [CURRENT_SELECTED_TAB, NAVIGATE_USER_PROFILE]),
    getTabIcon(index) {
      switch (index) {
        case 0:
          return index !== this.getCurrentTab ? UserIcon : UserIconActive;
        case 1:
          return index !== this.getCurrentTab ? OrderHistoryIcon : OrderHistoryIconActive;
        case 2:
          return index !== this.getCurrentTab ? RequestProductIcon : RequestProductIconActive;
        case 3:
          return index !== this.getCurrentTab ? WishlistIcon : WishlistIconActive;
        case 4:
          return index !== this.getCurrentTab ? ReferralIcon : ReferralIconActive;
        default:
          return '';
      }
    },
    navigateProfileTab(index) {
      this[NAVIGATE_USER_PROFILE](index);
    },
  },
};

</script>

<style lang="scss">
@import '@/styles/variables.scss';

.user-img {
  width: 60px;
  height: 60px;
  border-radius: 30px !important;
}

.username-text {
  font-family: Kanit;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: $black-00;
}

.store-text {
  font-family: Kanit;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: $black-00;
}

</style>
