<template>
  <div class="d-flex flex-column justify-center mb-3">
    <UserInfoDescription 
      custom
      title="ใบประกอบวิชาชีพ"
    >
      <div class="d-flex justify-space-between flex-grow-1">
        <div 
          @click="openLink(certificationFile)"
          class="user-cert-link d-flex align-center"
        >
          <img 
            v-if="certificationFile"
            width="14"
            height="14"
            :src="require('@/assets/images/icon/upload.png')"
          >
          <span class="profile__file__upload__text ml-2">{{certificationFile ? 'certification.pdf' : '-' }}</span>
        </div>
        <div class="profile__file__upload__edit__text-container d-flex align-center">
          <label
            for="certification-file-upload"
            class="d-flex align-center user-profile-upload"
          >
            <img 
              width="12"
              height="12"
              :src="require('@/assets/images/icon/pencil-edit-button-copy-5.png')"
            >
            <span class="profile__file__upload__edit__text ml-1">แก้ไข</span>
          </label>
        </div>
        <input
          id="certification-file-upload"
          type="file"
          @change="onCertificationFileChange"
        >
      </div>
    </UserInfoDescription>
    <UserInfoDescription 
      custom
      title="ใบอนุญาต"
    >
      <div class="d-flex justify-space-between flex-grow-1">
        <div 
          @click="openLink(permissionFile)"
          class="user-cert-link d-flex align-center"
        >
          <img 
            v-if="permissionFile"
            width="14"
            height="14"
            :src="require('@/assets/images/icon/upload.png')"
          >
          <span class="profile__file__upload__text ml-2">{{permissionFile ? 'permission.pdf' : '-'}}</span>
        </div>
        <div class="profile__file__upload__edit__text-container d-flex align-center">
          <label
            for="permission-file-upload"
            class="d-flex align-center user-profile-upload"
          >
            <img 
              width="12"
              height="12"
              :src="require('@/assets/images/icon/pencil-edit-button-copy-5.png')"
            >
            <span class="profile__file__upload__edit__text ml-1">แก้ไข</span>
          </label>
          <input
            id="permission-file-upload"
            type="file"
            @change="onPermissionFileChange"
          >
        </div>
      </div>
    </UserInfoDescription>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { uploadImage } from '@/services/upload-image';
import {
  GET_USER_INFORMATION,
  UPDATE_USER_INFORMATION,
  SHOW_SNACKBAR,
  CHANGE_SNACKBAR_MESSAGE,
  CHANGE_SNACKBAR_COLOR,
} from '@/store/_actionTypes';
import UserInfoDescription from './UserInfoDescription.vue';

export default {
  name: 'UserInfoCertificate',
  components: {
    UserInfoDescription,
  },
  computed: {
    ...mapState('user', [ 'accessToken', 'permissionFile', 'certificationFile' ]),
    ...mapGetters('user', [ 'getUserInfo' ]),
  },
  methods: {
    ...mapActions('app', [ SHOW_SNACKBAR, CHANGE_SNACKBAR_MESSAGE, CHANGE_SNACKBAR_COLOR ]),
    ...mapActions('user', [ GET_USER_INFORMATION, UPDATE_USER_INFORMATION ]),
    async onPermissionFileChange(e) {
      const file = e.target.files[0];
      const form = new FormData();
      form.append('file', file);

      try {
        const result = await uploadImage(form, this.accessToken);
        await this[UPDATE_USER_INFORMATION]({ permissionFileId: result.id });
        await this[GET_USER_INFORMATION](this.accessToken);
        this[SHOW_SNACKBAR](true);
        this[CHANGE_SNACKBAR_COLOR]('green');
        this[CHANGE_SNACKBAR_MESSAGE]('อัพโหลดสำเร็จ');
      } catch (error) {
        this[SHOW_SNACKBAR](true);
        this[CHANGE_SNACKBAR_COLOR]('red');
        this[CHANGE_SNACKBAR_MESSAGE](error.message);
      }
    },
    async onCertificationFileChange(e) {
      const file = e.target.files[0];
      const form = new FormData();
      form.append('file', file);

      try {
        const result = await uploadImage(form, this.accessToken);
        await this[UPDATE_USER_INFORMATION]({ certificationFileId: result.id });
        await this[GET_USER_INFORMATION](this.accessToken);
        this[SHOW_SNACKBAR](true);
        this[CHANGE_SNACKBAR_COLOR]('green');
        this[CHANGE_SNACKBAR_MESSAGE]('อัพโหลดสำเร็จ');
      } catch (error) {
        this[SHOW_SNACKBAR](true);
        this[CHANGE_SNACKBAR_COLOR]('red');
        this[CHANGE_SNACKBAR_MESSAGE](error.message);
      }
    },
    openLink(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss">

.profile__file__upload__edit__text,
.profile__file__upload__text {
  color: #751edf;
}

.profile__file__upload__edit__text-default {
  color: #21af25;
}

.profile__file__upload__edit__text-default,
.profile__file__upload__edit__text {
  font-family: Kanit;
  font-size: 12px;
}

.profile__file__upload__edit__text,
.profile__file__upload__edit__text-container {
  cursor: pointer;
}

#certification-file-upload,
#permission-file-upload {
  display: none;
}

.user-cert-link {
  cursor: pointer;
}

</style>