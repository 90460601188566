<template>
  <div class="max-width-600 pa-0">
    <span class="title-text">{{ title }}</span>
    <v-form
      ref="form"
      v-model="isFormValid"
      class="full-width"
      lazy-validation
    >
      <v-row class="mt-2 mb-12">
          <v-col
            cols="12"
            class="d-flex flex-column align-center justify-center justify-md-start align-md-start"
          >
            <label
              for="file-upload"
              class="custom-file-upload"
            >
              <div class="image__upload d-flex justify-center align-center">
                <div
                  v-if="!productImageUrl"
                  class="d-flex flex-column align-center"
                >
                  <img
                    :src="require('@/assets/images/icon/picture.png')"
                    class="default__upload__img"
                  >
                  <div class="d-flex justify-center align-center">
                    <img
                      :src="require('@/assets/images/icon/camera-copy.png')"
                      class="default__upload__img__icon mr-2"
                    >
                    <span>อัพโหลดรูปภาพ</span>
                  </div>
                </div>
                <img
                  v-if="productImageUrl"
                  class="pa-2"
                  :src="productImageUrl"
                >
              </div>
            </label>
            <input
              id="file-upload"
              type="file"
              @change="onFileChange"
            >
            <span
              v-if="productImageErrorMessage"
              class="error-text mt-1 ml-2"
            >
              {{ productImageErrorMessage }}
            </span>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <TextField
              :value="productName"
              :rules="productNameRules"
              title="ชื่อสินค้า"
              place-holder="ชื่อสินค้า"
              @setValue="setProductName"
            />
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <TextField
              :value="manufacturer"
              :rules="manufacturerRules"
              title="บริษัทที่จำหน่าย"
              place-holder="บริษัทที่จำหน่าย"
              @setValue="setManufacturer"
            />
          </v-col>
          <v-col
            cols="6"
            class="py-0"
          >
            <div class="mb-3">
              <label>จำนวนที่ต้องการซื้อ</label>
            </div>
            <v-text-field
              v-model="qty"
              type="number"
              outlined
              solo
              min="1"
              @change="onQtyChange"
            />
          </v-col>
          <v-col
            cols="6"
            class="py-0"
          >
            <TextField
              :value="unit"
              :rules="unitRules"
              title="หน่วย"
              place-holder="หน่วย"
              @setValue="setUnit"
            />
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div class="mb-3">
              <label>รายละเอียด</label>
            </div>
            <v-textarea
              v-model="description"
              outlined
              height="220"
              maxlength="250"
              :rules="[v => (v || '' ).length <= 250 || 'ตัวอักษรต้องน้อยกว่า 250 ตัว']"
              placeholder="รายละเอียด"
            />
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-end justify-md-start py-0 mt-n2"
          >
            <span class="request__product__offer__note__text">*รอการเสนอราคาจากทางร้านค้าประมาณ 3-7 วัน</span>
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-end justify-md-start py-0 mt-6"
          >
            <v-btn
              class="request__product__offer__btn text-capitalize py-6 px-16"
              color="secondary"
              depressed
              :loading="loading"
              @click="submitOffer()"
            >
              <span>Request สินค้า</span>
            </v-btn>
          </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { REQUEST_PRODUCT } from '@/store/_actionTypes';
import { CHANGE_SNACKBAR_MESSAGE, CHANGE_SNACKBAR_COLOR, SHOW_SNACKBAR } from '@/store/_actionTypes';

import TextField from '@/components/TextField.vue';

export default {
  name: 'RequestProductOffer',
  components: {
    TextField,
  },
  data: () => ({
    title: 'Request สินค้า',
    loading: false,
    isFormValid: false,
    productName: '',
    productNameRules: [
      (v) => !!v || 'กรุณากรอกชื่อสินค้า',
    ],
    manufacturer: '',
    manufacturerRules: [
      (v) => !!v || 'กรุณากรอกบริษัทที่จำหน่าย',
    ],
    unit: '',
    unitRules: [
      (v) => !!v || 'กรุณากรอกหน่วย',
    ],
    description: '',
    productImage: null,
    productImageUrl: null,
    productImageErrorMessage: '',
    qty: 1,
  }),
  computed: {
    ...mapState('user', [ 'accessToken' ]),
  },
  methods: {
    ...mapActions('request-product', [ REQUEST_PRODUCT ]),
    ...mapActions('app', [ SHOW_SNACKBAR, CHANGE_SNACKBAR_COLOR, CHANGE_SNACKBAR_MESSAGE ]),
    setProductName(value) {
      this.productName = value;
    },
    setManufacturer(value) {
      this.manufacturer = value;
    },
    setUnit(value) {
      this.unit = value;
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.productImage = file;
      this.productImageUrl = URL.createObjectURL(file);
      this.productImageErrorMessage = '';
    },
    onQtyChange(value) {
      this.qty = value < 1 ? 1 : value;
    },
    validateFile() {
      const imageValid = !!this.productImageUrl;
      if (!imageValid) {
        this.productImageErrorMessage = 'กรุณาอัพโหลดรูปสินค้า';
      }

      return imageValid;
    },
    async submitOffer() {
      this.loading = true;
      const isFileValid = this.validateFile();
      const isFormValid = this.$refs.form.validate();
      if (!isFileValid || !isFormValid) {
        this[SHOW_SNACKBAR](true);
        this[CHANGE_SNACKBAR_COLOR]('red');
        this[CHANGE_SNACKBAR_MESSAGE]('กรุณากรอกข้อมูลให้ครบถ้วน');
        this.loading = false;
        return;
      }

      const payload = {
        productName: this.productName,
        manufacturer: this.manufacturer,
        unit: this.unit,
        description: this.description,
        productImage: this.productImage,
        qty: this.qty
      }
      await this[REQUEST_PRODUCT]({ accessToken: this.accessToken, payload })

      this[SHOW_SNACKBAR](true);
      this[CHANGE_SNACKBAR_COLOR]('green');
      this[CHANGE_SNACKBAR_MESSAGE]('ส่งข้อมูลร้องขอสินค้าสำเร็จ');
      this.loading = false;

      setTimeout(() => {
        this.$router.go();
      }, 2500);
    }
  }
};
</script>

<style lang="scss">
@import '@/styles/fonts.scss';

.max-width-600 {
  max-width: 600px;
}

.v-input__slot {
  min-height: 43px !important;
  margin-bottom: 0 !important;
  box-shadow: unset !important;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  font-family: Kanit;
  font-size: 14px;
  line-height: 1.57;
  color: #242424;
}

label,
.form__label,
.image__upload__text,
.image__upload__text__limit {
    font-family: Kanit;
    font-size: 14px !important;
    line-height: 1.57;
    color: $black-00 !important;
}

#file-upload {
  display: none;
}

.default__upload__img {
  height: 40px !important;
  width: 55px !important;
  opacity: 0.5;
  margin-bottom: 10px;
}

.default__upload__img__icon {
  height: 12px !important;
  width: 14px !important;
}

.image__upload {
  width: 270px;
  height: 270px;
  border-radius: 4px;
  border: solid 1px $grey-01;
}

.image__upload span  {
  font-family: Kanit;
  font-size: 12px;
  line-height: 1.5;
  color: #751edf;
}

.image__upload img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.request__product__offer__btn {
  font-family: Kanit;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
}

.request__product__offer__note__text {
  font-family: Kanit;
  font-size: 12px;
  line-height: 1.5;
}

.error-text {
  font-family: Sarabun !important;
  font-size: 14px;
  color: $red-color-01;
}

</style>