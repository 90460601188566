<template>
  <div>
    <Dialog
      :is-show-dialog="isShowAddShippingAddressDialogValue"
      title="เพิ่มที่อยู่จัดส่ง"
      @setIsShowDialog="setIsShowDialog"
    >
      <v-form
        ref="form"
        v-model="isFormValid"
        class="add-delivery-location"
        lazy-validation
      >
        <TextField
          :value="pickupName"
          :rules="pickupNameRules"
          title="ชื่อผู้รับสินค้า"
          place-holder="ภัทรวดี ตามถิ่นไทย"
          required
          @setValue="setPickupName"
        />

        <div class="d-flex flex-column">
          <template>
            <ThailandAutoComplete
              v-model="postCode"
              type="zipcode"
              size="medium"
              label="รหัสไปรษณีย์"
              placeholder="รหัสไปรษณีย์"
              @select="setThailandAutoComplete"
              :class="isPostCodeError ? 'input-error' : ''"
            />
            <span
              v-if="isPostCodeError"
              class="input-error-message"
            >
              กรุณากรอกรหัสไปรษณีย์
            </span>
            <ThailandAutoComplete
              v-model="province"
              type="province"
              size="medium"
              label="จังหวัด"
              placeholder="จังหวัด"
              @select="setThailandAutoComplete"
              :class="isProvinceError ? 'input-error' : ''"
            />
            <span
              v-if="isProvinceError"
              class="input-error-message"
            >
              กรุณากรอกจังหวัด
            </span>
            <ThailandAutoComplete
              v-model="city"
              type="amphoe"
              size="medium"
              label="เขต / อำเภอ"
              placeholder="เขต / อำเภอ"
              @select="setThailandAutoComplete"
              :class="isCityError ? 'input-error' : ''"
            />
            <span
              v-if="isCityError"
              class="input-error-message"
            >
              กรุณากรอกเขต / อำเภอ
            </span>
            <ThailandAutoComplete
              v-model="subDistrict"
              type="district"
              size="medium"
              label="แขวง / ตำบล"
              placeholder="แขวง / ตำบล"
              @select="setThailandAutoComplete"
              :class="isSubDistrictError ? 'input-error' : ''"
            />
            <span
              v-if="isSubDistrictError"
              class="input-error-message"
            >
              กรุณากรอกแขวง / ตำบล
            </span>
          </template>
        </div>
        
        <TextField
          :value="address"
          :rules="addressRules"
          title="ที่อยู่"
          place-holder="รายละเอียดที่อยู่"
          required
          @setValue="setAddress"
        />

        <TextField
          :value="phoneNumber"
          :rules="phoneNumberRules"
          title="เบอร์โทรติดต่อ"
          place-holder="เบอร์โทรติดต่อ"
          required
          @setValue="setPhoneNumber"
        />

        <v-checkbox
          v-model="isDeliveryLocation"
          class="checkbox"
          label="เลือกเป็นที่อยู่สำหรับการจัดส่งสินค้า"
        />

        <div class="d-flex justify-end">
          <v-btn
            class="btn back"
            color="white"
            width="120"
            height="48"
            @click="back()"
          >
            กลับ
          </v-btn>
          <v-btn
            class="btn save"
            color="secondary"
            width="120"
            height="48"
            @click="save()"
          >
            บันทึก
          </v-btn>
        </div>
      </v-form>
    </Dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { searchAddressByDistrict, searchAddressByAmphoe, searchAddressByProvince } from 'thai-address-database';
import { getProvinces } from '@/helpers/address';

import Dialog from '@/components/Dialog.vue';
import TextField from '@/components/TextField.vue';
import Select from '@/components/Select.vue';

import {
  CREATE_SHIPPING,
  SHOW_SNACKBAR,
  CHANGE_SNACKBAR_MESSAGE,
  CHANGE_SNACKBAR_COLOR,
} from '@/store/_actionTypes';

export default {
  name: 'UserInfoAddShippingAddressDialog',
  components: {
    Dialog,
    TextField,
    Select,
  },
  props: {
    isShowAddShippingAddressDialog: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    isFormValid: false,
    pickupName: '',
    pickupNameRules: [
      (v) => !!v || 'กรุณากรอก ชื่อผู้รับสินค้า',
    ],
    postCode: '',
    postCodeRules: [
      (v) => !!v || 'กรุณากรอก รหัสไปรษณีย์',
    ],
    postCodes: [],
    address: '',
    addressRules: [
      (v) => !!v || 'กรุณากรอก ที่อยู่',
    ],
    phoneNumber: '',
    phoneNumberRules: [
      (v) => !!v || 'กรุณากรอก เบอร์โทรติดต่อ',
    ],
    city: '',
    cityRules: [
      (v) => !!v || 'กรุณากรอก เขต / อำเภอ',
    ],
    cities: [],
    province: '',
    provinceRules: [
      (v) => !!v || 'กรุณากรอก จังหวัด',
    ],
    provinces: getProvinces(),
    subDistrict: '',
    subDistrictRules: [
      (v) => !!v || 'กรุณากรอก แขวง / ตำบล',
    ],
    subDistricts: [],
    isDeliveryLocation: false,
    isPostCodeError: false,
    isCityError: false,
    isProvinceError: false,
    isSubDistrictError: false,
  }),
  computed: {
    isShowAddShippingAddressDialogValue: {
      get() {
        return this.isShowAddShippingAddressDialog;
      },
    },
  },
  methods: {
    ...mapActions('app', [SHOW_SNACKBAR, CHANGE_SNACKBAR_MESSAGE, CHANGE_SNACKBAR_COLOR]),
    ...mapActions('user', [CREATE_SHIPPING]),
    setThailandAutoComplete (address) {
      this.city = address.district
      this.subDistrict = address.amphoe
      this.province = address.province
      this.postCode = address.zipcode
      this.validateAddress();
    },
    validateAddress() {
      if (!this.city) {
        this.isCityError = true;
      } else {
        this.isCityError = false;
      }

      if (!this.postCode) {
        this.isPostCodeError = true;
      } else {
        this.isPostCodeError = false;
      }

      if (!this.province) {
        this.isProvinceError = true;
      } else {
        this.isProvinceError = false;
      }

      if (!this.subDistrict) {
        this.isSubDistrictError = true;
      } else {
        this.isSubDistrictError = false;
      }

      return !this.isCityError
        || !this.isPostCodeError
        || !this.isProvinceError
        || !this.isSubDistrictError;
    },
    setIsShowDialog(value) {
      this.$emit('setIsShowAddShippingAddressDialog', value);
    },
    setPickupName(value) {
      this.pickupName = value;
    },
    setPostCode(value) {
      this.postCode = value;
    },
    setCity(value) {
      const address = searchAddressByAmphoe(value, Number.MAX_SAFE_INTEGER);
      this.subDistricts = address.map(item => item.district);
      this.postCodes = address.map(item => item.zipcode);
      this.city = value;
    },
    setProvince(value) {
      const address = searchAddressByProvince(value, Number.MAX_SAFE_INTEGER);
      this.cities = address.map(item => item.amphoe);
      this.subDistricts = address.map(item => item.district);
      this.postCodes = address.map(item => item.zipcode);
      this.province = value;
    },
    setSubDistrict(value) {
      const address = searchAddressByDistrict(value, Number.MAX_SAFE_INTEGER);
      this.postCodes = address.map(item => item.zipcode);
      this.subDistrict = value;
    },
    setAddress(value) {
      this.address = value;
    },
    setPhoneNumber(value) {
      this.phoneNumber = value;
    },
    async save() {
      const isAddressValid = this.validateAddress();
      const isFormValid = this.$refs.form.validate();

      if (!isFormValid || !isAddressValid) {
        this[SHOW_SNACKBAR](true);
        this[CHANGE_SNACKBAR_COLOR]('red');
        this[CHANGE_SNACKBAR_MESSAGE]('กรุณากรอกข้อมูลให้ครบถ้วน');
        this.loading = false;
        return;
      }

      const shipping = {
        name: this.pickupName,
        address: this.address,
        province: this.province,
        district: this.city,
        subDistrict: this.subDistrict,
        postcode: this.postCode,
        phoneNumber: this.phoneNumber,
        isDefault: this.isDeliveryLocation,
      };
      try {
        await this[CREATE_SHIPPING](shipping);

        this[CHANGE_SNACKBAR_MESSAGE]('สร้างที่อยู่ใหม่สำเร็จ');
        this[CHANGE_SNACKBAR_COLOR]('green');
        this[SHOW_SNACKBAR](true);

        this.pickupName = '';
        this.address = '';
        this.province = '';
        this.city = '';
        this.district = '';
        this.subDistrict = '';
        this.postCode = '';
        this.phoneNumber = '';
        this.isDeliveryLocation = '';
        this.$refs.form.reset();
        
        this.back();
      } catch (error) {
        this[CHANGE_SNACKBAR_MESSAGE]('ล้มเหลว กรุณาลองใหม่อีกครั้ง');
        this[CHANGE_SNACKBAR_COLOR]('red');
        this[SHOW_SNACKBAR](true);
      }
    },
    back() {
      this.setIsShowDialog(false);
    },
  },
};
</script>

<style lang="scss">
  .add-delivery-location {
    .v-input--selection-controls {
      margin: 0 !important;
    }

    .btn {
      font-family: Kanit;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;

      &.back {
        border: none;
        box-shadow: none;
        color: $black-00;
      }

      &.save {
        box-shadow: none;
      }
    }

    .checkbox {
      label {
        font-family: Kanit;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        color: $black-00;
      }
    }
  }

  .input-error > div > input {
  border: 2px solid #ff5252 !important;

  &:hover {
    border-color: #ff5252 !important;
    }
  }

  input.vth-addr-input-size-medium {
    box-shadow: unset !important;
    border-color: #a0a0a0 !important;
    border-radius: 4px !important;
    font-family: Kanit;
    font-size: 14px !important;
    font-weight: 400;
    line-height: 2 !important;
    color: #242424 !important;

    &:hover {
      border-color: #242424 !important;
    }

    &::-webkit-input-placeholder {
      font-family: Kanit;
      font-size: 14px;
      color: #a0a0a0;
    }
  }

  .vth-addr-label {
    font-family: Kanit;
    font-size: 14px;
    color: #242424 !important;
  }

  .input-error-message {
    margin-top: -.3rem;
    padding-left: 12px;
    margin-bottom: 12px;
    color: #ff5252;
    font-size: 12px;
  }
</style>
