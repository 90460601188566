<template>
  <div class="profile__description d-flex mt-3">
    <div>
      <span class="profile__description__header">{{ title }}</span>
    </div>
    <span v-if="!custom">{{ description }}</span>
    <slot />
  </div>
</template>

<script>
export default {
    name: 'UserInfoDescription',
    props: {
        title: {
            type: String,
            default: () => '',
        },
        description: {
            type: String,
            default: () => '',
        },
        custom: {
            type: Boolean,
            default: () => false,
        }
  },
};
</script>

<style lang="scss">

.profile__description > div {
    width: 125px;
}

.profile__description {
    font-family: Sarabun;
}

.profile__description__header {
    font-weight: 600;
}

</style>