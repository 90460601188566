<template>
  <div class="mb-6">
    <UserInfoDescription 
      title="ชื่อผู้รับสินค้า"
      :description="shipping.name"
    >
      <div class="d-flex justify-end flex-grow-1">
        <div class="d-flex align-center mr-4">
          <span
            v-if="shipping.isDefault"
            class="profile__file__upload__edit__text-default"
          >ที่อยู่จัดส่งหลัก</span>
          <span
            v-else
            class="profile__file__upload__edit__text"
            @click="setDefaultShipping(shipping.id)"
          >เลือกเป็นที่อยู่จัดส่งหลัก</span>
        </div>
        <div 
          class="profile__file__upload__edit__text-container d-flex align-center"
          @click="updateShippingAddress"
        >
          <img 
            width="12"
            height="12"
            :src="require('@/assets/images/icon/pencil-edit-button-copy-5.png')"
          >
          <span class="profile__file__upload__edit__text ml-1">แก้ไข</span>
        </div>
      </div>
    </UserInfoDescription>
    <UserInfoDescription 
      title="ที่อยู่รับพัสดุ"
      :description="shipping.address"
    />
    <UserInfoDescription 
      title="เบอร์โทรติดต่อ"
      :description="shipping.phoneNumber"
    />
    <UserInfoUpdateShippingAddressDialog 
      :is-show-update-shipping-address-dialog="isShowUpdateShippingAddressDialog"
      @setIsShowUpdateShippingAddressDialog="setIsShowUpdateShippingAddressDialog"
      :shipping="shipping"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { UPDATE_DEFAULT_SHIPPING } from '@/store/_actionTypes';

import UserInfoDescription from './UserInfoDescription.vue';
import UserInfoUpdateShippingAddressDialog from './UserInfoUpdateShippingAddressDialog.vue';

export default {
  name: 'UserInfoShippingAddressDescription',
  components: {
    UserInfoDescription,
    UserInfoUpdateShippingAddressDialog,
  },
  props: {
    shipping: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isShowUpdateShippingAddressDialog: false,
  }),
  computed: {
    ...mapState('user', [ 'accessToken' ]),
  },
  methods: {
    ...mapActions('user', [ UPDATE_DEFAULT_SHIPPING ]),
    setDefaultShipping(shippingId) {
      this[UPDATE_DEFAULT_SHIPPING]({ accessToken: this.accessToken, shippingId });
    }, 
    updateShippingAddress() {
      this.isShowUpdateShippingAddressDialog = true;
    },
    setIsShowUpdateShippingAddressDialog(value) {
      this.isShowUpdateShippingAddressDialog = value;
    },
  },
};
</script>
