<template>
  <Dialog
    width="560"
    :is-show-dialog="isShowCancelOrderDialog"
    @setIsShowDialog="setIsShowCancelOrderDialog"
  >
    <div class="cancel-order d-flex flex-column pl-8 pr-8">
      <span class="title-text">ยกเลิกคำสั่งซื้อ</span>
      <v-divider class="mt-3" />
      <span class="sub-title-text mt-6 mb-6">คุณต้องการยืนยันยกเลิกคำสั่งซื้อใช่หรือไม่?</span>
      <div
        :class="getClassName(isMobileScreenSize,'d-flex justify-space-around btn-group mb-4', 'd-flex flex-column align-center btn-group')"
      >
        <v-btn
          :class="getClassName(isMobileScreenSize,'btn border-grey','mobile')"
          outlined
          @click="onConfirmClick"
        >
          ยืนยันการยกเลิก
        </v-btn>
        <v-btn
          :class="getClassName(isMobileScreenSize,'btn','mobile mt-4')"
          color="secondary"
          @click="setIsShowCancelOrderDialog(false)"
        >
          ปิด
        </v-btn>
      </div>
    </div>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialog.vue';
import { mapActions, mapState } from 'vuex';
import { CANCEL_ORDER } from '@/store/_actionTypes';
import { getClassName } from '@/helpers/className';

export default {
  name: 'CancelOrderDialog',
  components: { Dialog },
  props: {
    isShowCancelOrderDialog: {
      type: Boolean,
      default: () => false,
    },
    orderId: {
      type: String,
      default: () => null,
    },
  },
  computed: {
    ...mapState('app', ['isMobileScreenSize']),
  },
  methods: {
    getClassName,
    ...mapActions('order', [CANCEL_ORDER]),
    setIsShowCancelOrderDialog(value) {
      this.$emit('setIsShowCancelOrderDialog', value);
    },
    onConfirmClick() {
      this[CANCEL_ORDER](this.orderId);
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/fonts.scss';

.cancel-order {
  .title-text {
    @include kanit;
    font-size: 20px;
    line-height: 1.5;
    color: $black-00;
    text-align: center;
  }

  .sub-title-text {
    @include kanit;
    font-size: 14px;
    line-height: 1.5;
    color: $black-00;
    text-align: center;
  }

  .btn-group {
    .btn {
      @include kanit;

      width: 40%;
      height: 48px;

      &.mobile {
        width: 100%;
      }

      font-size: 16px;
      line-height: 1.5;

      &.border-grey {
        border-color: $white-01 !important;
      }
    }
  }
}
</style>
