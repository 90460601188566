<template>
  <div class="summary pa-3">
    <div class="d-flex justify-space-between pr-3 pl-3 mb-1">
      <div class="text">
        ราคารวม
      </div>
      <div class="text-price">
        {{ getPriceText(totalProductPrice) }}
      </div>
    </div>
    <div class="d-flex justify-space-between pr-3 pl-3 mb-1">
      <div class="text">
        ค่าจัดส่ง
      </div>
      <div class="text-price">
        {{ getPriceText(deliveryPrice) }}
      </div>
    </div>
    <div class="d-flex justify-space-between pr-3 pl-3 mb-2">
      <div class="text">
        โค้ดส่วนลด
      </div>
      <div class="text-discount">
        -{{ getPriceText(discountPrice) }}
      </div>
    </div>

    <div class="total">
      <div class="d-flex justify-space-between">
        <span class="text-total">รวมทั้งสิ้น</span>
        <span class="text-total-price">{{ getPriceText(totalPrice) }}</span>
      </div>
      <div class="d-flex justify-space-between">
        <span class="text">ได้รับ Capsule ทั้งหมด</span>
        <span class="text-total-capsule">{{ capsuleText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatPrice, formatNumber} from "@/helpers/number";

export default {
  name: 'Summary',
  computed: {
    ...mapGetters('order', [
      'totalProductPrice',
      'deliveryPrice',
      'discountPrice',
      'totalPrice',
      'capsule'
    ]),
    capsuleText() {
      return `${formatNumber(this.capsule, 0)} Capsule Points`
    }
  },
  methods: {
    getPriceText(price) {
      return formatPrice(price);
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/fonts.scss';

.summary {
  border-radius: 6px;
  border: solid 1px $white-01;

  .total {
    padding: 8px 12px 13px;
    border-radius: 6px;
    border: dashed 1px $grey-01;
    background-color: $white-02;
  }

  .text {
    @include sarabun;
    line-height: 1.57;
    color: $black-00;
  }

  .text-price {
    @include sarabun;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    color: $black-00;
  }

  .text-discount {
    @include sarabun;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    color: $red-color-00;
  }

  .text-total {
    @include sarabun;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: $black-00;

    &-price {
      @include sarabun;
      font-size: 20px;
      font-weight: 600;
      line-height: 1.57;
      color: $primary-color-02;
    }

    &-capsule {
      @include sarabun;
      font-size: 14px;
      line-height: 1.5;
      color: $primary-color-02;
    }
  }
}
</style>
