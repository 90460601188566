var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-details"},[(_vm.orderStatus != 'cancel')?_c('Stepper',{attrs:{"step":_vm.orderStep},on:{"setStep":_vm.setOrderStep}},_vm._l((_vm.orderSteps),function(ref,orderStepKey){
var imageSrc = ref.imageSrc;
var step = ref.step;
var title = ref.title;
var imageSrcSet = ref.imageSrcSet;
return _c('div',{key:orderStepKey,class:_vm.getClassName(
          _vm.isMobileScreenSize,
          'step d-flex flex-column justify-center align-center',
          'mobile mb-5'
        )},[_c('v-img',{class:_vm.getStepClassName(
            _vm.orderStep > orderStepKey,
            'step-image mb-4',
            'step-image incomplete mb-4'
          ),attrs:{"src":imageSrc,"srcset":imageSrcSet,"contain":"","height":"50px"}}),_c('v-stepper-step',{class:_vm.getStepClassName(_vm.orderStep > orderStepKey, '', 'step-container-incomplete'),attrs:{"complete":_vm.orderStep > orderStepKey,"complete-icon":_vm.getCompleteIcon(step),"color":"secondary","step":""}},[_c('v-divider')],1),_c('div',{staticClass:"step-title"},[_c('span',{staticClass:"step-label pl-1 pr-1 mt-4"},[_vm._v(_vm._s(title))])])],1)}),0):_vm._e(),_vm._m(0),_vm._l((_vm.orderDetails),function(ref,key){
      var productImage = ref.productImage;
      var productId = ref.productId;
      var productType = ref.productType;
      var productName = ref.productName;
      var productGenericName = ref.productGenericName;
      var productPrice = ref.productPrice;
      var amount = ref.amount;
      var extraAmount = ref.extraAmount;
      var unit = ref.unit;
return _c('div',{key:key,staticClass:"d-flex justify-space-between mb-3 align-center"},[_c('div',{staticClass:"d-flex",staticStyle:{"width":"50%"}},[_c('div',{staticClass:"product-image-container mr-3"},[_c('img',{attrs:{"src":productImage || _vm.NotFoundProductImage}})]),_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"text product-name"},[_vm._v(_vm._s(_vm.getProductName(productType, productId, productName)))]),_c('span',{staticClass:"text generic-name mt-1"},[_vm._v(_vm._s(productGenericName))]),_c('span',{staticClass:"text mt-1"},[_vm._v(_vm._s(_vm.getAmount(amount, extraAmount, unit)))])])]),_c('div',{staticStyle:{"width":"25%","text-align":"right"}},[_c('span',{staticClass:"text product-price"},[_vm._v(_vm._s(_vm.getPrice(productPrice)))])]),_c('div',{staticStyle:{"width":"25%","text-align":"right"}},[_c('span',{staticClass:"text product-price"},[_vm._v(_vm._s(_vm.getTotalPrice(productPrice, amount)))])])])})],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-space-between mt-2 mb-3"},[_c('div',{staticClass:"text",staticStyle:{"width":"50%"}},[_vm._v(" รายการสินค้า ")]),_c('div',{staticClass:"text",staticStyle:{"width":"25%","text-align":"right"}},[_vm._v(" ราคา/หน่วย ")]),_c('div',{staticClass:"text",staticStyle:{"width":"25%","text-align":"right"}},[_vm._v(" ราคารวม ")])])}]

export { render, staticRenderFns }