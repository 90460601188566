<template>
  <div class="product-request__banner d-flex flex-column flex-md-row align-center mt-5">
    <img
      :src="require('@/assets/images/banner/product-offer.png')"
      class="full-width hidden-sm-and-down"
    >
    <div class="d-flex flex-column d-md-none d-lg-none">
      <span class="banner__text">
        ไม่มีสินค้าที่คุณต้องการในร้านค้าของเรา...
      </span>
      <span class="banner__text">
        นรินทร์เภสัชจัดหามาให้คุณใหม่ได้!
      </span>
    </div>
    <v-btn
      class="product-request__btn text-capitalize pa-6"
      color="secondary"
      depressed
      to="/request-product/offer"
    >
      <span>Request สินค้า</span>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'RequestProductBanner',
};
</script>

<style lang="scss">

.banner__text {
  font-family: Kanit;
  text-align: center;
}

.product-request__banner {
  position: relative;
}

.product-request__btn {
  position: absolute;
  right: 24px;
  font-family: Kanit;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

@media only screen and (max-width: 960px) {
  .product-request__btn {
    position: relative;
    right: 0;
    margin-top: 8px;
  }
}

</style>