<template>
  <div class="d-flex align-center">
    <img 
      width="14"
      height="16"
      :src="icon"
    >
    <span class="title-text text-purple mx-3">{{ title }}</span>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'UserInfoHeader',
  props: {
    icon: {
      type: String,
      default: () => require('@/assets/images/icon/combined-shape.png'),
    },
    title: {
      type: String,
      default: () => '',
    },
  },
};
</script>

<style lang="scss">
.text-purple {
    color: #300463;
}
</style>