<template>
  <div class="d-flex flex-column justify-center mb-3">
    <UserInfoDescription 
      title="บ้านเลขที่ / ถนน"
      :description="this.getUserInfo.storeAddress || '-'"
    />
    <UserInfoDescription 
      title="เบอร์โทร"
      :description="this.getUserInfo.phoneNumber || '-'"
    />
    <UserInfoDescription 
      title="อีเมล"
      :description="this.getUserInfo.email || '-'"
    />
    <UserInfoDescription 
      title="Facebook"
      :description="this.getUserInfo.facebook || '-'"
    />
    <UserInfoDescription 
      title="LINE ID"
      :description="this.getUserInfo.line || '-'"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserInfoDescription from './UserInfoDescription.vue';

export default {
  name: 'UserInfoContact',
  components: {
    UserInfoDescription,
  },
  computed: {
    ...mapGetters('user', [ 'getUserInfo' ]),
  },
};
</script>
