<template>
  <div>
    <UserInfoHeader 
      :icon="require('@/assets/images/icon/combined-shape.png')"
      title="ข้อมูลส่วนตัว"
    />
    <UserInfoPersonal />
    <v-divider class="mb-3" />

    <UserInfoHeader 
      :icon="require('@/assets/images/icon/shape.png')"
      title="ข้อมูลที่อยู่จัดส่ง"
    />
    <UserInfoShippingAddress />
    <v-divider class="mb-3" />

    <UserInfoHeader 
      :icon="require('@/assets/images/icon/notebook-of-contacts.png')"
      title="ข้อมูลติดต่อ"
    >
      <div 
        class="profile__file__upload__edit__text-container d-flex align-center"
        @click="setIsShowUpdateContactDialog(true)"
      >
        <img 
          width="12"
          height="12"
          :src="require('@/assets/images/icon/pencil-edit-button-copy-5.png')"
        >
        <span class="profile__file__upload__edit__text ml-1">แก้ไข</span>
      </div>
      <UserInfoUpdateContactDialog 
        :is-show-update-contact-dialog="isShowUpdateContactDialog"
        @setIsShowUpdateContactDialog="setIsShowUpdateContactDialog"
        :userInfo="getUserInfo"
      />
    </UserInfoHeader>
    <UserInfoContact />
    <v-divider class="mb-3" />

    <UserInfoHeader 
      :icon="require('@/assets/images/icon/file.png')"
      title="ใบรับรอง &amp; ใบอนุญาต"
    />
    <UserInfoCertificate />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import UserInfoHeader from './components/UserInfoHeader.vue';
import UserInfoPersonal from './components/UserInfoPersonal.vue';
import UserInfoShippingAddress from './components/UserInfoShippingAddress.vue';
import UserInfoContact from './components/UserInfoContact.vue';
import UserInfoCertificate from './components/UserInfoCertificate.vue';
import UserInfoUpdateContactDialog from './components/UserInfoUpdateContactDialog.vue';

export default {
  name: 'UserInfo',
  components: {
    UserInfoHeader,
    UserInfoPersonal,
    UserInfoShippingAddress,
    UserInfoContact,
    UserInfoCertificate,
    UserInfoUpdateContactDialog,
  },
  data: () => ({
    title: 'ข้อมูลส่วนตัว',
    postCode: '',
    url: null,
    qty: 1,
    isShowUpdateContactDialog: false,
  }),
  computed: {
    ...mapGetters('user', [ 'getUserInfo' ]),
  },
  methods: {
    setPostCode(value) {
      this.postCode = value;
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
    },
    onQtyChange(value) {
      this.qty = value < 1 ? 1 : value;
    },
    setIsShowUpdateContactDialog(value) {
      this.isShowUpdateContactDialog = value;
    },
  }
};
</script>

<style lang="scss">

</style>