<template>
  <div class="order-details">
    <Stepper
      v-if="orderStatus != 'cancel'"
      :step="orderStep"
      @setStep="setOrderStep"
    >
      <div
        v-for="({ imageSrc, step, title, imageSrcSet }, orderStepKey) in orderSteps"
        :key="orderStepKey"
        :class="
          getClassName(
            isMobileScreenSize,
            'step d-flex flex-column justify-center align-center',
            'mobile mb-5',
          )
        "
      >
        <v-img
          :class="
            getStepClassName(
              orderStep > orderStepKey,
              'step-image mb-4',
              'step-image incomplete mb-4',
            )
          "
          :src="imageSrc"
          :srcset="imageSrcSet"
          contain
          height="50px"
        />
        <v-stepper-step
          :class="getStepClassName(orderStep > orderStepKey, '', 'step-container-incomplete')"
          :complete="orderStep > orderStepKey"
          :complete-icon="getCompleteIcon(step)"
          color="secondary"
          step=""
        >
          <v-divider />
        </v-stepper-step>
        <div class="step-title">
          <span class="step-label pl-1 pr-1 mt-4">{{ title }}</span>
        </div>
      </div>
    </Stepper>

    <div class="d-flex justify-space-between mt-2 mb-3">
      <div
        class="text"
        style="width:50%"
      >
        รายการสินค้า
      </div>
      <div
        class="text"
        style="width:25%;text-align:right"
      >
        ราคา/หน่วย
      </div>
      <div
        class="text"
        style="width:25%;text-align:right"
      >
        ราคารวม
      </div>
    </div>
    <div
      v-for="(
        {
          productImage,
          productId,
          productType,
          productName,
          productGenericName,
          productPrice,
          amount,
          extraAmount,
          unit,
        },
        key
      ) in orderDetails"
      :key="key"
      class="d-flex justify-space-between mb-3 align-center"
    >
      <div
        class="d-flex"
        style="width:50%"
      >
        <div class="product-image-container mr-3">
          <img :src="productImage || NotFoundProductImage">
        </div>
        <div class="d-flex flex-column">
          <span class="text product-name">{{ getProductName(productType, productId, productName) }}</span>
          <span class="text generic-name mt-1">{{ productGenericName }}</span>
          <span class="text mt-1">{{ getAmount(amount, extraAmount, unit) }}</span>
        </div>
      </div>
      <div style="width:25%;text-align:right">
        <span class="text product-price">{{ getPrice(productPrice) }}</span>
      </div>
      <div style="width:25%;text-align:right">
        <span class="text product-price">{{ getTotalPrice(productPrice, amount) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { getClassName } from '@/helpers/className';
import { formatPrice, formatNumber } from "@/helpers/number";
import NotFoundProductImage from '@/assets/images/medicine/not-found-product.png';

import Stepper from './Stepper.vue';

export default {
  name: 'Details',
  components: {
    Stepper,
  },
  data: () => ({
    NotFoundProductImage: NotFoundProductImage,
  }),
  props: {
    orderDetails: {
      type: Array,
      default: () => [],
    },
    orderStatus: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState('app', [ 'isMobileScreenSize' ]),
    ...mapGetters('order', [ 'getOrderDetailPaymentType', 'orderSteps', 'orderStep' ]),
  },
  methods: {
    getClassName,
    setOrderStep(value) {
      this.orderStep = value;
    },
    getProductName(productType, productId, productName) {
      return productType === 1 ? `${productId} ${productName}` : productName;
    },
    getAmount(amount, extraAmount, unit) {
      const extraAmountText = extraAmount ? ` ( แถม ${ formatNumber(extraAmount) } ${ unit } )` : '';
      return !!extraAmountText ? `x ${ formatNumber(amount) } ${ unit } ${ extraAmountText }`
        : `x ${ formatNumber(amount) } ${ unit }`;
    },
    getPrice(productPrice) {
      return productPrice > 0 ? formatPrice(productPrice) : productPrice;
    },
    getCompleteIcon(step) {
      return `mdi-numeric-${ step }`;
    },
    getStepClassName(isComplete, completeClass, incompleteClass) {
      return isComplete ? completeClass : incompleteClass;
    },
    getTotalPrice(productPrice, amount) {
      return productPrice > 0 ? formatPrice(productPrice * amount) : productPrice;
    }
  }
};
</script>

<style lang="scss">
@import '@/styles/fonts.scss';

.order-details {
  padding: 24px;
  border-radius: 6px;
  border: solid 1px $white-01;
  background-color: $white-00;

  &.mobile {
    padding: 16px;
  }

  .text {
    @include sarabun;
    color: $black-00;
    line-height: 1.57;

    &.product-name {
      font-weight: 600;
    }

    &.generic-name {
      font-size: 12px;
      line-height: 1.5;
      color: $grey-02;
    }

    &.product-price {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.5;
      color: $primary-color-02;
    }
  }
}

.step {
  width: 33.33%;

  .step-container-incomplete {
    .v-stepper__label {
      hr {
        width: 100%;
        border-color: $white-01 !important;
      }
    }

    .v-stepper__step__step {
      background: $white-01 !important;
    }
  }
}

.step-label {
  @include kanit;
  font-size: 12px;
  text-align: center;
}

.step-image {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.incomplete {
    opacity: 0.3;
  }
}

.product-image-container {
  width: 80px;
  height: 80px;
  padding: 9px 8px 8px 9px;
  border-radius: 4px;
  border: solid 1px $white-01;

  img {
    width: 100%;
    height: 100%;
  }
}

.step-title {
  height: 50px;
}
</style>
