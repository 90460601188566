var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.requestProducts,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return _vm._l((items),function(item,key){return _c('tr',{key:key,staticClass:"request-product__table__tr",on:{"click":function($event){return _vm.onRequestProductClick(item)}}},[_c('td',{class:_vm.getClassName('request-product__table__td')},[_c('div',{class:[
                'd-flex',
                'full-width',
                'justify-space-between',
                'justify-md-start'
              ]},[(_vm.isMobileScreenSize)?_c('span',[_vm._v("สินค้า")]):_vm._e(),_c('div',{staticClass:"d-flex justify-space-between justify-md-start full-width"},[_c('img',{staticClass:"product-request__img align-self-start",attrs:{"src":("" + (item.productImage && item.productImage.url ? item.productImage.url : '')),"width":"70"}}),_c('div',{class:_vm.getClassName('product-request__product__detail__container d-flex flex-column pl-4')},[_c('span',[_vm._v(_vm._s(item.productName))]),_c('span',[_vm._v("x "+_vm._s(item.requestQty))])])])])]),_c('td',{class:_vm.getClassName('request-product__table__td')},[_c('div',{class:[
                'd-flex',
                'full-width',
                'justify-space-between',
                'justify-md-center'
              ]},[(_vm.isMobileScreenSize)?_c('span',[_vm._v("วัน/เวลา อัพเดทสถานะ")]):_vm._e(),_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.getUpdatedTime(item.updatedAt))+" ")])])]),_c('td',{class:_vm.getClassName('request-product__table__td')},[_c('div',{class:[
                'd-flex',
                'full-width',
                'justify-space-between',
                'justify-md-center'
              ]},[(_vm.isMobileScreenSize)?_c('span',[_vm._v("สถานะ")]):_vm._e(),_c('div',{staticClass:"product-request__product__status d-flex justify-center align-center"},[_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status)}},[_vm._v(" "+_vm._s(_vm.getStatusText(item.status))+" ")])],1)])])])})}}])}),_c('div',{staticClass:"mt-10 align-self-end"},[(_vm.requestProducts.length > 0)?_c('Pagination',{attrs:{"total-items":_vm.total,"page":_vm.page,"items":_vm.requestProducts,"labels":_vm.customLabels,"page-size":_vm.pageSize,"styles":_vm.customStyles},on:{"changePage":_vm.onChangePage}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }