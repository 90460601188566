<template>
  <div class="d-flex flex-column">
    <div class="mt-2">
      <input
        ref="uploader"
        accept="image/*"
        class="d-none"
        type="file"
        @change="onFileChanged"
      >
      <v-btn
        class="upload-btn pt-2 pb-2"
        color="secondary"
        outlined
        @click="uploadButtonClick"
      >
        <v-progress-circular
          v-if="isShowUploadProgress"
          color="secondary"
          indeterminate
          size="16"
          width="2"
        />
        <div
          v-else
          class="d-flex align-center"
        >
          <v-img
            :src="uploadIcon"
            class="mr-3"
            contain
          />
          อัพโหลดไฟล์
        </div>
      </v-btn>

      <span class="file-name-text ml-4">{{ selectedFileName }}</span>
    </div>
    <span
      v-if="hasErrorMessage"
      class="error-text ml-3 mt-1"
    >
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
import UploadIcon from '@/assets/images/logo/ic-upload.svg';
import { mapActions, mapState } from 'vuex';
import { SHOW_UPLOAD_PROGRESS, UPLOAD_IMAGE_ACTION } from '@/store/_actionTypes';

export default {
  name: 'UploadFile',
  props: {
    fileUrl: {
      type: [String, Number],
      default: () => '',
    },
    errorMessage: {
      type: String,
      default: () => '',
    },
  },
  data: () => ({
    uploadIcon: UploadIcon,
    selectedFileName: '',
  }),
  computed: {
    ...mapState('upload-image', ['isShowUploadProgress']),
    fileUrlValue: {
      get() {
        return this.fileUrl;
      },
      set(value) {
        this.$emit('setFileUrl', value);
      },
    },
    errorMessageValue: {
      get() {
        return this.errorMessage;
      },
      set(value) {
        this.$emit('setErrorMessage', value);
      },
    },
    hasErrorMessage() {
      return !!this.errorMessage;
    },
  },
  watch: {
    fileUrl() {
      if (!this.fileUrl) {
        this.selectedFileName = '';
      }
    },
  },
  methods: {
    ...mapActions('upload-image', [UPLOAD_IMAGE_ACTION, SHOW_UPLOAD_PROGRESS]),
    async onFileChanged(e) {
      try {
        this[SHOW_UPLOAD_PROGRESS](true);
        const selectedFile = e?.target?.files[0];
        const result = await this[UPLOAD_IMAGE_ACTION](selectedFile);
        if (result) {
          this.selectedFileName = selectedFile.name;
          this.$emit('setFileUrl', result.id);
          this.setErrorMessage('');
        }
      } catch (error) {
        this.setErrorMessage(`เกิดข้อผิดพลาดกรุณาลองใหม่ภายหลัง : ${error}`);
      } finally {
        this[SHOW_UPLOAD_PROGRESS](false);
      }
    },
    uploadButtonClick() {
      this.$refs.uploader.click();
    },
    setErrorMessage(value) {
      this.$emit('setErrorMessage', value);
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/fonts.scss';

.error-text {
  @include kanit;
  font-size: 12px;
  color: $red-color-01;
}

.file-name-text {
  @include kanit;
  font-size: 14px;
  line-height: 1.5;
  color: $black-00;
}
</style>
