<template>
  <v-container class="pa-0">
    <span class="title-text">{{ title }}</span>
    <v-row class="mt-2 mb-12">
      <div 
        v-if="wishlistProduct.length === 0" 
        class="d-flex flex-column align-center full-width my-16"
      >
        <span class="wishlist__not__found__text">ยังไม่มีสินค้าที่คุณถูกใจ</span>
        <span class="wishlist__again__text">กดปุ่มรูปหัวใจเพื่อบันทึกสินค้าที่ถูกใจ</span>
      </div>
      <v-col
        v-for="(product, n) in wishlistProduct"
        :key="`wishlist-${n}`"
        cols="12"
        sm="6"
        md="3"
      >
        <ProductCard 
          :product="product" 
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { GET_WISHLIST_PRODUCT } from '@/store/_actionTypes';

import ProductCard from '@/components/ProductCard';

export default {
  name: 'Wishlist',
  components: {
    ProductCard,
  },
  data: () => ({
    title: 'รายการ Wishlist',
  }),
  created() {
    this[GET_WISHLIST_PRODUCT](this.accessToken);
  },
  computed: {
    ...mapState('user', [ 'accessToken' ]),
    ...mapState('wishlist', [ 'wishlistProduct' ]),
  },
  methods: {
    ...mapActions('wishlist', [ GET_WISHLIST_PRODUCT ])
  },
};
</script>

<style lang="scss">
.wishlist__not__found__text {
  font-family: Kanit;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
  color: #242424;
}

.wishlist__again__text {
  font-family: Sarabun;
  font-size: 12px;
  line-height: 1.57;
  color: $grey-02;
}
</style>