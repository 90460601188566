<template>
  <div>
    <v-stepper
      v-model="stepValue"
      class="stepper"
    >
      <v-stepper-header :class="getClassName(isMobileScreenSize, 'header', 'header mobile')">
        <slot />
      </v-stepper-header>
    </v-stepper>
  </div>
</template>

<script>
import {mapState} from 'vuex';

import {getClassName} from '@/helpers/className';

export default {
  name: 'Stepper',
  props: {
    step: {
      type: Number,
      default: () => 1,
    },
  },
  computed: {
    stepValue: {
      get() {
        return this.step;
      },
      set(value) {
        this.$emit('setStep', value);
      },
    },
    ...mapState('app', ['isMobileScreenSize']),
  },
  methods: {
    getClassName,
  },
};
</script>

<style lang="scss">
@import '@/styles/fonts.scss';

.v-stepper {
  box-shadow: none !important;
}

.stepper {
  hr {
    border-color: $primary-color-02 !important;
  }

  .v-stepper__header {
    height: 100%;
    justify-content: center;
  }

  .v-stepper__step {
    flex-direction: row-reverse;
    padding: 0;
    width: 100% !important;
  }

  .v-stepper__step__step {
    min-width: 16px !important;
    width: 16px !important;
    height: 16px !important;

    @include kanit;
    font-size: 8px;
    line-height: 1.5;
    color: $black-00;
  }

  .v-stepper__label {
    display: flex !important;
    width: 100% !important;

    hr {
      width: 100%;
    }
  }
}
</style>
