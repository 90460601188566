<template>
  <v-card
    class="ecommerce__card__container rounded-lg pa-0"
    elevation="0"
    outlined
    :href="`/product/${this.product.id}`"
    target="_self"
  >
    <div
      v-if="isPromotionValid()"
      class="ecommerce__tag__discount d-flex justify-center align-center"
    >
      <span>{{ getPromotionBadge() }}</span>
    </div>
    <template>
      <v-btn
        icon
        text
        :color="isFavorites(product.id) ? 'pink' : 'grey'"
        class="ecommerce__tag__favourite"
        @click.prevent
        @click="addToWishList(isFavorites(product.id))"
      >
        <v-icon>{{ isFavorites(product.id) ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
      </v-btn>
    </template>
    <div
      v-if="product.visibility == 0"
      class="ecommerce__tag__outofstock d-flex justify-center align-center"
    >
      <span>สินค้าหมด</span>
    </div>
    <div class="d-flex justify-center">
      <img
        :src="getProductImage()"
        class="ecommerce__product__image"
      />
    </div>
    <div class="d-flex flex-column pa-4 flex-grow-1 flex-shrink-0">
      <span class="ecommerce__product__name">{{ product.name }}</span>
      <div class="ecommerce__price__discount-spacer">
        <span class="ecommerce__product__generic_name">{{ product.genericName }}</span>
      </div>
      <div class="ecommerce__price__discount-spacer">
        <span
          v-if="isDiscountPromotionValid(product)"
          class="ecommerce__price__discount"
        >
          {{ getTotalPrice() }}
        </span>
      </div>
      <span class="ecommerce__price mb-2">{{ isDiscountPromotionValid(product) ? getDiscountPrice() : getTotalPrice() }}</span>
      <template>
        <v-btn
          outlined
          depressed
          color="secondary"
          :class="product.visibility == 0 ? 'ecommerce__card__btn mt-auto disabled' : 'ecommerce__card__btn mt-auto'"
          @click.prevent
          @click="addToCart"
        >
          <v-icon class="mr-2">
            mdi-basket
          </v-icon>
          เพิ่มลงตะกร้า
        </v-btn>
      </template>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { ADD_ITEM, CHANGE_SNACKBAR_MESSAGE, SHOW_SNACKBAR, CHANGE_SNACKBAR_COLOR, ADD_WISHLIST_PRODUCT, REMOVE_WISHLIST_PRODUCT, UPDATE_FAVORITES } from '@/store/_actionTypes';
import { formatNumber } from "@/helpers/number";
import {
  isDiscountAmountPromotionValid,
  isDiscountPercentagePromotionValid,
  isDiscountPromotionValid,
  isFreeGiftPromotionValid,
  isCapsuleAmountPromotionValid,
  isCapsuleMultiplyPromotionValid,
} from "@/helpers/promotion";
import NotFoundProductImage from '@/assets/images/medicine/not-found-product.png';

export default {
  name: 'ProductCard',
  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('user', ['isLoggedIn']),
    ...mapState('user', [ 'accessToken', 'favorites' ]),
  },
  methods: {
    ...mapActions('cart', [ ADD_ITEM ]),
    ...mapActions('user', [ UPDATE_FAVORITES ]),
    ...mapActions('app', [ SHOW_SNACKBAR, CHANGE_SNACKBAR_MESSAGE, CHANGE_SNACKBAR_COLOR ]),
    ...mapActions('wishlist', [ ADD_WISHLIST_PRODUCT, REMOVE_WISHLIST_PRODUCT ]),
    isDiscountPromotionValid,
    isPromotionValid() {
      return isDiscountAmountPromotionValid(this.product)
        || isDiscountPercentagePromotionValid(this.product)
        || isFreeGiftPromotionValid(this.product)
        || isCapsuleAmountPromotionValid(this.product)
        || isCapsuleMultiplyPromotionValid(this.product);
    },
    isFavorites(productId) {
      return this.favorites?.includes(productId);
    },
    addToWishList(isWishlist) {
      if (!this.isLoggedIn) {
        this[SHOW_SNACKBAR](true);
        this[CHANGE_SNACKBAR_MESSAGE]('กรุณาเข้าสู่ระบบก่อนใช้งาน');
        this[CHANGE_SNACKBAR_COLOR]('red');
      } else {
        if (isWishlist) {
          this[REMOVE_WISHLIST_PRODUCT]({ product: this.product, accessToken: this.accessToken });
          this[UPDATE_FAVORITES](this.favorites.filter(item => item !== this.product.id));
        } else {
          let favorites = [...this.favorites, this.product.id];
          this[ADD_WISHLIST_PRODUCT]({ product: this.product, accessToken: this.accessToken });
          this[UPDATE_FAVORITES](favorites);
        }
      }
    },
    addToCart() {
      if (this.product.visibility == 0) return;

      const product = {
        id: this.product.id,
        barcode: this.product.barcode,
        name: this.product.name,
        genericName: this.product.genericName,
        qty: 1,
        priceRates: this.product.priceRates,
        promotionType: this.product.promotionType,
        promotionFreeBuy: this.product.promotionFreeBuy,
        promotionFreeGift: this.product.promotionFreeGift,
        deliveryFee: this.product.deliveryFee,
        discount: this.product.promotionDiscountAmount,
        discountPercentage: this.product.promotionDiscountPercentage,
        promotionCapsuleAmount: this.product.promotionCapsuleAmount,
        promotionCapsuleMultiply: this.product.promotionCapsuleMultiply,
        promotionStartdate: this.product.promotionStartdate,
        promotionEnddate: this.product.promotionEnddate,
        capsule: this.product.capsule,
        unit: this.product.unit,
        image: this.getProductImage(),
        isCapsule: false,
        selected: true,
        stock: this.product.stock
      }
      this[ADD_ITEM]({ qty: 1, product });
    },
    getPromotionBadge() {
      if (isDiscountAmountPromotionValid(this.product))
        return `-฿${this.product.promotionDiscountAmount}`;
      if (isDiscountPercentagePromotionValid(this.product))
        return `-${this.product.promotionDiscountPercentage}%`;
      if (isFreeGiftPromotionValid(this.product))
        return `${this.product.promotionFreeBuy} แถม ${this.product.promotionFreeGift}`;
      if (isCapsuleAmountPromotionValid(this.product))
        return `+${this.product.promotionCapsuleAmount} capsules`;
      if (isCapsuleMultiplyPromotionValid(this.product))
        return `x${this.product.promotionCapsuleMultiply} capsules`;
    },
    getDiscountPrice() {
      const priceRange = [...this.product.priceRates].sort((a, b) => a.priceUnit - b.priceUnit);
      let minPrice = priceRange[0].priceUnit;
      let maxPrice = priceRange[priceRange.length - 1].priceUnit;
      let discount = 0;

      if (isDiscountAmountPromotionValid(this.product)) {
        discount = this.product.promotionDiscountAmount;
        minPrice = parseInt(minPrice - discount);
        maxPrice = parseInt(maxPrice - discount);
      } else if (isDiscountPercentagePromotionValid(this.product)) {
        discount = this.product.promotionDiscountPercentage;
        minPrice = parseInt(minPrice - (minPrice * discount / 100));
        maxPrice = parseInt(maxPrice - (maxPrice * discount / 100));
      }

      if (minPrice < 0) minPrice = 0;
      if (maxPrice < 0) maxPrice = 0;

      if (priceRange.length === 1) {
        return `฿${formatNumber(minPrice)} / ${this.product.unit}`;
      } else {
        return `฿${formatNumber(minPrice)} - ${formatNumber(maxPrice)} / ${this.product.unit}`;
      }
    },
    getTotalPrice() {
      const priceRange = [...this.product.priceRates].sort((a, b) => a.priceUnit - b.priceUnit);
      let minPrice = priceRange[0].priceUnit;
      let maxPrice = priceRange[priceRange.length - 1].priceUnit;
      if (priceRange.length === 1) {
        return `฿${formatNumber(minPrice)} / ${this.product.unit}`;
      } else {
        return `฿${formatNumber(minPrice)} - ${formatNumber(maxPrice)} / ${this.product.unit}`;
      }
    },
    getProductImage() {
      if (!this.product.images || this.product.images.length === 0)
        return NotFoundProductImage;

      return this.product.images[0].url;
    },
  }
};
</script>

<style scoped lang="scss">
.ecommerce__product__name {
    font-family: Sarabun;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.57;
    min-height: 42px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ecommerce__product__generic_name {
    font-family: Sarabun;
    font-size: 14px;
    font-weight: 800;
    line-height: 1.57;
    min-height: 42px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
}

.ecommerce__price {
    font-family: Sarabun;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    color: #21af25;
    margin-top: auto;
}

.ecommerce__price__discount {
    font-family: Sarabun;
    font-size: 12px;
    line-height: 1.5;
    color: #999999;
    text-decoration: line-through;
}

.ecommerce__card__btn {
    font-family: Kanit;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.57;
    letter-spacing: unset !important;
    height: 45px !important;

    &.disabled {
      color: rgba(0,0,0,.26)!important;
    }
}

.ecommerce__card__container {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
}

.ecommerce__tag__discount {
    padding: 0 8px;
    height: 35px;
    border-top-right-radius: 100px !important;
    border-bottom-right-radius: 100px !important;
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.15);
    background-color: #c8f672;
    font-family: Kanit;
    font-size: 14px;
    line-height: 1.57;
    color: #242424;
    position: absolute;
    top: 10px;
    left: 0;
    z-index: 2;
}

.ecommerce__tag__favourite {
    position: absolute !important;
    top: 5px;
    right: 5px;
    z-index: 2;
}

.ecommerce__tag__outofstock {
    position: absolute !important;
    top: 80px;
    z-index: 2;
    height: 55px;
    background-color: rgba(240, 84, 84, 0.6);
    width: 100%;
    font-family: Kanit;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    color: #ffffff;
}

.ecommerce__product__image{
  height: 200px;
  max-width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.ecommerce__price__discount-spacer {
  height: 18px;
}

@media (max-width: 600px) {
  .ecommerce__product__image {
    max-height: 110px;
  }
}

</style>
