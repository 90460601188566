<template>
  <div class="payment-option d-flex flex-column mt-4 mb-4">
    <span class="label-text mb-2">รูปแบบการชำระเงิน:</span>
    <span class="delivery-details-text mb-2">{{ paymentOption }}</span>
    <div
      v-for="({
        accountName,
        accountBranch,
        accountNumber,
        imageSrc,
        imageSrcSet,
        className }, key) in paymentOptions.filter(item => item.bankName == 'กสิกรไทย')"
      :key="key"
      :class="getClassName(
        isMobileScreenSize,
        getBankIconClassName('d-flex option mb-2', className),
        'mobile')"
    >
      <div
        :class="getClassName(
          isMobileScreenSize,
          'option-left d-flex justify-start align-center',
          'mobile')"
      >
        <img
          class="image mr-4"
          :src="imageSrc"
          :srcset="imageSrcSet"
        >
        <div>
          <div class="text">
            {{ getAccountName(accountName) }}
          </div>
          <div class="text">
            {{ getAccountBranch(accountBranch) }}
          </div>
        </div>
      </div>
      <div
        :class="getClassName(
          isMobileScreenSize,
          'option-right d-flex justify-end align-center',
          'mobile')"
      >
        <span class="text-account-number">{{ getAccountฺNumber(accountNumber) }}</span>
      </div>
    </div>
    <span class="text mb-2">{{ transferDate }}</span>
    <span class="text mb-2">{{ transferPrice }}</span>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import { getClassName } from '@/helpers/className';

export default {
  name: 'PaymentOption',
  computed: {
    ...mapState('app', ['isMobileScreenSize']),
    ...mapGetters('order', ['paymentOption', 'transferDate', 'transferPrice', 'paymentOptions']),
  },
  methods: {
    getAccountName(accountName) {
      return `ชื่อบัญชี ${accountName}`;
    },
    getAccountBranch(accountBranch) {
      return `สาขา ${accountBranch}`;
    },
    getAccountฺNumber(accountNumber) {
      return `เลขที่บัญชี ${accountNumber}`;
    },
    getBankIconClassName(defaultClassName, className) {
      return `${defaultClassName} ${className}`;
    },
    getClassName,
  },
};
</script>

<style lang="scss">
@import '@/styles/fonts.scss';

.payment-option {
  .option {
    width: 100%;
    padding: 2%;
    border-radius: 6px;

    &.mobile {
      flex-direction: column;
    }

    &.kbank {
      border: solid 1px rgba(0, 168, 66, 0.2);
      background-color: rgba(0, 168, 66, 0.05);
    }

    &.krungthai {
      border: solid 1px rgba(0, 189, 236, 0.2);
      background-color: rgba(0, 189, 236, 0.05);
    }

    &.krungthep {
      border: solid 1px rgba(0, 50, 159, 0.2);
      background-color: rgba(98, 138, 225, 0.05);
    }

    &.scb {
      border: solid 1px rgba(85, 38, 135, 0.2);
      background-color: rgba(171, 126, 219, 0.05);
    }

    &-left {
      width: 50%;

      .image {
        width: 40px;
        height: 40px;
      }

      &.mobile {
        width: 100%;
      }
    }

    &-right {
      width: 50%;

      &.mobile {
        width: 100%;
      }
    }

    .text {
      @include sarabun;
      font-size: 12px;
      line-height: 1.5;
      color: $black-00;
    }

    .text-account-number {
      @include sarabun;
      font-size: 14px;
      line-height: 1.57;
      color: $black-00;
    }
  }
}
</style>
