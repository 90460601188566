<template>
  <v-container class="pa-0">
    <v-row class="d-flex flex-column">
      <v-col class="d-flex flex-column">
        <span class="title-text align-self-start">Request สินค้า</span>
        <RequestProductBanner />
      </v-col>
      <v-col class="d-flex flex-column">
        <div class="d-flex flex-column flex-md-row justify-space-between align-md-center mb-3">
          <span class="title-text align-self-start">ประวัติการ Request สินค้า</span>
          <div class="d-flex align-center justify-md-start justify-space-between mt-1 mt-md-0">
            <span class="product-request__text mr-3">สถานะใบสั่งซื้อ</span>
            <v-menu
              key="Large"
              rounded="lg"
              offset-y
            >
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  class="product-request__text d-flex justify-center align-center"
                  v-bind="attrs"
                  small
                  depressed
                  v-on="on"
                >
                  {{ status }}
                  <v-icon
                    dark
                    right
                    medium
                  >
                    mdi-chevron-down
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="item in items"
                  :key="item"
                  link
                  @click="setStatus(item)"
                >
                  <v-list-item-title
                    class="product-request__text"
                    v-text="item"
                  />
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <RequestProductTable
          :headers="headers"
          :request-products="requestProducts"
          :on-request-product-click="onRequestProductClick"
          :status="getStatusValue(status)"
        />
        <RequestProductDialog
          :request-product-selected-data="requestProductSelectedData"
          :is-show-request-product-dialog="isShowRequestProductDialog"
          @setIsShowRequestProductDialog="setIsShowRequestProductDialog"
          @updatingStatus="updatingStatus"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  SHOW_REQUEST_PRODUCT_DIALOG,
  GET_REQUEST_PRODUCT,
  CHANGE_SNACKBAR_MESSAGE,
  SHOW_SNACKBAR,
  CHANGE_SNACKBAR_COLOR
} from '@/store/_actionTypes';
import RequestProductBanner from './components/RequestProductBanner.vue';
import RequestProductDialog from './components/RequestProductDialog.vue';
import RequestProductTable from './components/RequestProductTable.vue';

export default {
  name: 'RequestProduct',
  components: {
    RequestProductBanner,
    RequestProductDialog,
    RequestProductTable,
  },
  data: () => ({
    status: 'ทั้งหมด',
    headers: [
      { text: 'สินค้า', value: 'product_name' },
      { text: 'วัน/เวลา อัพเดทสถานะ', value: 'updated_date', align: 'center' },
      { text: 'สถานะ', value: 'status', align: 'center' },
    ],
    items: [
      'ทั้งหมด',
      'สินค้าเข้าระบบแล้ว',
      'รอสินค้าเข้าระบบ',
      'รอโอนมัดจำ',
      'รอตอบรับการเสนอราคา',
      'รอเสนอราคา',
      'ถูกปฏิเสธ',
      'ปฏิเสธการเสนอราคา',
    ],
    requestProductSelectedData: {},
  }),
  created() {
    this[GET_REQUEST_PRODUCT]({ accessToken: this.accessToken})
  },
  computed: {
    ...mapState('user', [ 'accessToken' ]),
    ...mapState('request-product', [ 'requestProducts', 'total', 'page' ]),
    ...mapGetters('request-product', ['isShowRequestProductDialog']),
  },
  methods: {
    ...mapActions('request-product', [ SHOW_REQUEST_PRODUCT_DIALOG, GET_REQUEST_PRODUCT]),
    ...mapActions('app', [ SHOW_SNACKBAR, CHANGE_SNACKBAR_MESSAGE, CHANGE_SNACKBAR_COLOR ]),
    onRequestProductClick(item) {
      this.requestProductSelectedData = item;
      this[SHOW_REQUEST_PRODUCT_DIALOG](true);
    },
    setIsShowRequestProductDialog(value) {
      this[SHOW_REQUEST_PRODUCT_DIALOG](value);
    },
    updatingStatus(value){
      this[SHOW_SNACKBAR](true);
      if (value) {
        this[CHANGE_SNACKBAR_COLOR]('green');
        this[CHANGE_SNACKBAR_MESSAGE]('ส่งข้อมูลสำเร็จ');
      } else {
        this[CHANGE_SNACKBAR_COLOR]('red');
        this[CHANGE_SNACKBAR_MESSAGE]('ส่งข้อมูลไม่สำเร็จ โปรดลองใหม่อีกครั้งในภายหลัง');
      }

      setTimeout(() => {
         this.$router.go();
      }, 2000);
    },
    getStatusValue(value) {
      switch (value) {
        case 'ถูกปฏิเสธ': {
          return 'reject_request';
        }
        case 'ปฏิเสธการเสนอราคา': {
          return 'reject_offer';
        }
        case 'รอเสนอราคา': {
          return 'waiting_offer';
        }
        case 'รอตอบรับการเสนอราคา': {
          return 'waiting_response_offer';
        }
        case 'รอโอนมัดจำ': {
          return 'waiting_deposit';
        }
        case 'รอสินค้าเข้าระบบ': {
          return 'waiting_register_product';
        }
        case 'สินค้าเข้าระบบแล้ว': {
          return 'product_registered';
        }
        default: {
          return '';
        }
      }
    },
    setStatus(status) {
      this.status = status;
      this[GET_REQUEST_PRODUCT](
        {
          accessToken: this.accessToken,
          status: this.getStatusValue(this.status)
        }
      );
    }
  },
};
</script>

<style lang="scss">
.full-width {
  width: 100%;
}

.product-request__text {
  font-family: Sarabun;
  font-size: 14px !important;
  line-height: 1.57 !important;
  color: #242424;
}
</style>
